import React from 'react';

class VendorVideoAssetUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  uploadVideo = (e) => {
    this.props.uploadVendorAssetVideo(e);
  };

  render() {
    return (
      <>
        <div className="row margin-top-20 margin-left-10">
          <div className="add-listing-section ">
            {/* margin-top-45 */}
            {/* <div className="add-listing-headline">
              <h3>
                <i className="im im-icon-File-Video"></i> Upload Video
              </h3>
            </div> */}
            <div className="submit-section">
              <form className="dropzone">
                <img
                  src="../images/upload-videos-icon.53b7ee18.svg"
                  alt="Upload Asset"
                />
                <input
                  type="file"
                  className="upload_deal_image upload_vendor_asset_image"
                  accept="video/*"
                  onChange={(e) => this.uploadVideo(e)}
                />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default VendorVideoAssetUploader;
