import React from 'react';
import './Stripe.css';
import { Rings } from 'react-loader-spinner';
import { Link } from 'react-router-dom';
import { Modal, Button, Card } from 'react-bootstrap';

import { Elements } from '@stripe/react-stripe-js';
import AuthService from '../services/AuthService';
import axios from 'axios';
import CheckoutForm from './CheckoutForm';

class VendorDashboardSubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriptionData: this.props.subscriptionData,
      vendorProfile: this.props.vendorProfile,
      subscriptionDuration: 'Monthly',
      isOpen: false,
      clientSecret: null,
    };
  }
  // componentDidMount() {
  //   const apiUrl = 'https://api.eventmassi.com/api/v2/vendor-profile-me';

  //   fetch(apiUrl)
  //     .then((response) => response.json())
  //     .then((data) => console.log('This is your data', data));
  // }
  handleSubmit = async (event) => {
    let token = AuthService.getToken();
    event.preventDefault();

    let clientSecret = await axios
      .post(
        'https://api.eventmassi.com/api/v2/payment-intent',
        {
          paymentData: {
            name: 'Monthly',
            currency: 'USD',
            price_id: 'price_1I9iTKL4WzBwsDIqF24AIKlq',
            product_id: 'prod_IlExq7kAKk9Vjz',
            _id: '628332a5e5699073141806b1',
            price: '14.99',
          },
        },
        {
          headers: {
            Authorization: `Bearer ` + token,
          },
        }
      )
      .then(function (response) {
        console.log('response:', response.data.clientSecret);
        return response.data.clientSecret;
      })
      .catch(function (error) {
        console.log(error);
      });

    this.setState({ clientSecret: clientSecret, isOpen: true });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.subscriptionData === null &&
      this.props.subscriptionData !== null
    ) {
      this.setState({ subscriptionData: this.props.subscriptionData });
    }
  }

  changeDuration = (value) => {
    value
      ? this.setState({ subscriptionDuration: 'Anually' })
      : this.setState({ subscriptionDuration: 'Monthly' });
  };
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false });
  render() {
    const { subscriptionData } = this.state;
    // const { vendorProfile } = this.state;
    // console.log(vendorProfile);
    console.log(subscriptionData, this.state.subscriptionDuration);

    return (
      <>
        <div className="d-flex align-items-center justify-content-center"></div>
        <Modal>{/* ... */}</Modal>
        <Modal show={this.state.isOpen} onHide={this.closeModal}>
          {/* <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              X
            </Button>
          </Modal.Footer> */}
          {/* <Modal.Header closeButton></Modal.Header> */}

          {/* <div>
            <Elements stripe={stripePromise}>
              <CheckoutForm />
            </Elements>
          </div> */}

          <div className="p-4">
            <img
              className="mx-auto d-flex"
              src="https://www.beta.eventmassi.com/_next/static/media/logo.a3765cc6.svg"
              alt=""
            />
            <CheckoutForm clientSecret={this.state.clientSecret} />
          </div>
        </Modal>

        {/* {vendorProfile == null ? <></> : ''} */}
        {subscriptionData == null ? (
          <div
            className="col-lg-12"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Rings
              height="200"
              width="200"
              radius="9"
              color="#edb300"
              ariaLabel="three-dots-loading"
            />
          </div>
        ) : (
          <>
            <div className="tabTopBar ">
              <p>
                <strong>Subscribe!</strong> to preferred membership &amp;
                enchance your business. To subscribe <a href="#_">Click Here</a>
              </p>
            </div>
            <div className="container margin-bottom-35">
              <div className="col-md-12">
                <div className="row padding-top-15">
                  <div className="col-lg-12 text-center">
                    <h2>Vendor Subscriptions</h2>
                  </div>
                </div>

                <div className="row text-center subscription-duration">
                  <label className="switch mx-auto d-flex">
                    <input
                      type="checkbox"
                      onChange={(e) => this.changeDuration(e.target.checked)}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="row">
                  <div className="col-md-12 ">
                    <div className="pricing-container margin-top-10">
                      {subscriptionData.length > 0 ? (
                        <>
                          {subscriptionData.map((packagedata) => {
                            return (
                              <div className="plan" key={packagedata._id}>
                                <div className="plan-price text-left">
                                  <h5 className="fw-bold business">
                                    {packagedata.business}
                                  </h5>
                                  <h1 className="fw-bold">
                                    {packagedata.name}
                                  </h1>
                                  <span className="value fw-bold">
                                    {packagedata.packages.map((price) => {
                                      return packagedata.name === 'Free'
                                        ? 'Free'
                                        : price.name.trim() ===
                                          this.state.subscriptionDuration
                                        ? '$' + price.price
                                        : null;
                                    })}
                                    <span className="fs-5 monthly text-left">
                                      <span className="mx-2  ">/</span>{' '}
                                      {this.state.subscriptionDuration}
                                    </span>
                                  </span>
                                </div>
                                {packagedata.services.length > 0 ? (
                                  <div className="plan-features">
                                    <h4 className="text-left mb-2  fw-bold">
                                      What's Included
                                    </h4>
                                    <ul>
                                      {packagedata.services.map((service) => {
                                        return (
                                          <li
                                            className="text-left  fw-bold"
                                            key={service._id}
                                          >
                                            <img
                                              className="mx-3"
                                              style={{ width: '20px' }}
                                              src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Eo_circle_yellow_checkmark.svg/2048px-Eo_circle_yellow_checkmark.svg.png"
                                              alt="im"
                                            ></img>
                                            {service.description}
                                          </li>
                                        );
                                      })}
                                    </ul>

                                    <button
                                      onClick={this.handleSubmit}
                                      className="button text-light sub-btn border w-100"
                                      // href="#!"
                                    >
                                      Buy Now
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            );
                          })}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default VendorDashboardSubscription;
