import React, { useState, useEffect } from 'react';
import './blogs.css';
import axios from 'axios';

const Blogs = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get('https://api.eventmassi.com/api/v2//get-all-blogs-list')
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div className="container-fluid">
      {' '}
      <hr></hr>
      <div>
        {data.map((product, index) => (
          <div key={index}>
            {/*  */}
            <div className="blogPostWrap firstBlogPost">
              <div className="insideBlogPost">
                <div className="blogImgWrap">
                  <img src={product.image} alt="" />
                </div>
                <div className="insideBlogDetails">
                  <h4>{`${product.title}`}</h4>
                  <p>{`${product.description}`}</p>
                  <span className="blogPostTime">
                    <span>3 Min Ago</span>
                  </span>
                </div>
              </div>
            </div>
            {/*  */}
          </div>
        ))}
      </div>
      <br></br>
      <br></br>
    </div>
  );
};

export default Blogs;
