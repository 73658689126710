import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Local_routes } from '../../util/constants';
import LoginSignupModal from '../../components/LoginSignupModal';
import SignupModal from '../../components/LoginSignupModal2';
import SearchBar from './Search';
import './InstaFeed.css';
import { DebounceInput } from 'react-debounce-input';

import './layout.css';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchData: null,
      Drawer: false,
      signinModal: false,
      signupModal: false,
      userAuth: localStorage.getItem('userToken')
        ? atob(localStorage.getItem('userToken'))
        : null,
      userData: localStorage.getItem('userData')
        ? JSON.parse(atob(localStorage.getItem('userData')))
        : null,
      headerTab: window.location.pathname,
    };
  }

  search(key) {
    fetch(
      'https://api.eventmassi.com/api/v2/get-all-searches?searchword=' + key
    ).then((data) => {
      data.json().then((resp) => {
        console.log('resp', resp.data);
        this.setState({ searchData: resp.data });
      });
    });
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.loginFormShow !== this.props.loginFormShow) {
      this.setState({ signupModal: this.props.loginFormShow });
    }
  }

  //handle drawer naviggation
  toogledrawer = (value) => {
    this.setState({ Drawer: value });
  };

  loginModalClose = (redirect = false) => {
    this.props.closeLoginModal();
    this.setState({
      signinModal: false,
      signupModal: false,
      userAuth: localStorage.getItem('userToken')
        ? atob(localStorage.getItem('userToken'))
        : null,
      userData: localStorage.getItem('userData')
        ? JSON.parse(atob(localStorage.getItem('userData')))
        : null,
    });
    if (redirect) {
      this.props.navigate('/dashboard', {
        userAuth: this.state.userAuth,
        userData: this.state.userData,
      });
    }
  };

  logoutUser = () => {
    this.setState({ userAuth: null, userData: null });
    localStorage.removeItem('userToken');
    localStorage.removeItem('userData');
    this.props.navigate('/');
  };

  setActiveHeaderTab = (tabname) => {
    this.setState({ headerTab: tabname });
  };

  render() {
    const { userData, userAuth } = this.state;
    return (
      <>
        <header className="topHeader">
          <div className="container">
            <nav className="navbar navbar-expand-lg namobileHidevbar-light">
              <Link
                className="navbar-brand"
                to="/"
                onClick={() => this.setActiveHeaderTab(Local_routes.home)}
              >
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/logo.a3765cc6.svg"
                  alt="img"
                />
              </Link>
              <div className="desktopHide desktopHideHeaderRightLink">
                <Link className="headerRightLink mx-4">
                  <img
                    src="https://www.beta.eventmassi.com/_next/static/media/header-top-right-icon.9eb0db27.svg"
                    alt="img"
                  />
                </Link>
              </div>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <SearchBar />
                {/* <form className="headerSearchForm desktopSearchForm">
                  <img
                    src="https://www.beta.eventmassi.com/_next/static/media/search-icon.8302d07b.svg"
                    alt="img"
                  />
                  <DebounceInput
                    // minLength={3}
                    debounceTimeout={500}
                    className="form-control mr-sm-2"
                    placeholder="Search"
                    aria-label="Search"
                    type="search"
                    onChange={(event) => this.search(event.target.value)}
                  />
                </form> */}

                <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                    <Link
                      className="nav-link cursor-pointer"
                      to={Local_routes.home}
                      onClick={() => this.setActiveHeaderTab(Local_routes.home)}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <Link
                      className="nav-link"
                      to={Local_routes.vendors}
                      onClick={() =>
                        this.setActiveHeaderTab(Local_routes.vendors)
                      }
                    >
                      Browse Vendors
                    </Link>
                  </li>
                  {userAuth && userData ? (
                    <li className="nav-item cursor-pointer">
                      <Link
                        className="nav-link"
                        to={Local_routes.explore}
                        onClick={() =>
                          this.setActiveHeaderTab(Local_routes.explore)
                        }
                      >
                        Explore
                      </Link>
                    </li>
                  ) : (
                    ''
                  )}
                  <li className="nav-item cursor-pointer">
                    <Link
                      className="nav-link"
                      to={Local_routes.blogs}
                      onClick={() =>
                        this.setActiveHeaderTab(Local_routes.blogs)
                      }
                    >
                      Blogs
                    </Link>
                  </li>
                  <li className="nav-item cursor-pointer">
                    <Link
                      className="nav-link"
                      to={Local_routes.contact_us}
                      onClick={() =>
                        this.setActiveHeaderTab(Local_routes.contact_us)
                      }
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
                <div className="topHeaderRightSide">
                  <ul className="topHeaderRightNav">
                    <li>
                      {/* User Menu */}
                      {userAuth && userData ? (
                        <>
                          <Link
                            className={''}
                            to={Local_routes.dashboard}
                            onClick={() =>
                              this.setActiveHeaderTab(Local_routes.home)
                            }
                          >
                            <div className="user-menu">
                              <div className="user-name">
                                <span>
                                  {userData.pic_path === '' ||
                                  userData.pic_path === null ||
                                  userData.pic_path === 'undefined' ? (
                                    <img
                                      src="https://www.beta.eventmassi.com/_next/static/media/header-top-right-icon.9eb0db27.svg"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      // src={`${URLS.S3BucketURL}${userData.pic_path}`}
                                      src="https://www.beta.eventmassi.com/_next/static/media/header-top-right-icon.9eb0db27.svg"
                                      onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = './images/default.jpeg';
                                      }}
                                      alt={'User Profile pic'}
                                    />
                                  )}
                                </span>
                              </div>
                            </div>
                          </Link>
                          <a href="#!" onClick={() => this.logoutUser()}>
                            Logout
                          </a>
                        </>
                      ) : (
                        <a
                          href="#!"
                          onClick={() =>
                            this.setState({
                              signinModal: true,
                              signupModal: false,
                            })
                          }
                        >
                          Log in
                        </a>
                      )}
                    </li>
                    <li>
                      <li>
                        {!userAuth && !userData ? (
                          <a
                            className="signUp"
                            href="#!"
                            onClick={() =>
                              this.setState({
                                signinModal: false,
                                signupModal: true,
                              })
                            }
                          >
                            <span>Sign Up</span>
                          </a>
                        ) : (
                          ''
                        )}
                      </li>
                    </li>
                    {/* <li>
                      <li>
                        <a
                          className="signUp"
                          href="#!"
                          onClick={() =>
                            this.setState({
                              signinModal: false,
                              signupModal: true,
                            })
                          }
                        >
                          <span>Sign Up</span>
                        </a>
                      </li>
                    </li> */}
                  </ul>
                </div>
              </div>
            </nav>
            {/* <SearchBar /> */}
            {/* <div className="mobileSearchForm mb-5">
              <form className="headerSearchForm "> */}
            {/* <img
                  src="https://www.beta.eventmassi.com/_next/static/media/search-icon.8302d07b.svg"
                  alt="img"
                />
                <input
                  type="search"
                  className="form-control mr-sm-2"
                  placeholder="Search"
                  aria-label="Search"
                /> */}
            {/* </form>
            </div> */}

            {/* <div className="list ">
              <div className="dataResult px-3 ">
                <h3>Vendors</h3>
                <div>
                  {this.state.searchData ? (
                    <div>
                      {this.state.searchData.vendors.map((item) => (
                        <Link
                          to={Local_routes.vendor_detail + '/' + btoa(item.id)}
                          class="vendorInsideContentDetails"
                        >
                          {item.business}
                        </Link>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="categoryResult px-3">
                <h3>Categories</h3>
                <div>
                  {this.state.searchData ? (
                    <div>
                      {this.state.searchData.categories.map((item) => (
                        <a
                          className="dataItem"
                          href={item.link}
                          target="_blank"
                        >
                          <p>{item.title} </p>
                        </a>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="keywordResult px-3">
                <h3>Keywords</h3>
                <div>
                  {this.state.searchData ? (
                    <div>
                      {this.state.searchData.keywords.map((item) => (
                        <a
                          className="dataItem"
                          href={item.link}
                          target="_blank"
                        >
                          <p>{item.name} </p>
                        </a>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="stateResult px-3">
                <h3>States</h3>
                <div>
                  {this.state.searchData ? (
                    <div>
                      {this.state.searchData.states.map((item) => (
                        <a
                          className="dataItem"
                          href={item.link}
                          target="_blank"
                        >
                          <p>{item.name} </p>
                        </a>
                      ))}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div> */}
          </div>
        </header>

        <LoginSignupModal
          signinupModal={this.state.signinModal}
          loginModalClose={(redirect) => this.loginModalClose(redirect)}
        />
        <SignupModal
          signinupModal={this.state.signupModal}
          loginModalClose={(redirect) => this.loginModalClose(redirect)}
        />
      </>
    );
  }
}

function HeaderRoute(props) {
  let navigate = useNavigate();
  return <Header {...props} navigate={navigate} />;
}

export default HeaderRoute;
