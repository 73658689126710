import React from 'react';
import withRouter from '../../helper/withRouter';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';
import VendorService from '../../services/VendorService';
import ExploreVendorDetail from '../../components/ExploreVendorDetail';
import { Rings } from 'react-loader-spinner';
import VendorDetail from '../../components/VendorDetail';
class ExploreVendor extends React.Component {
  state = {
    vendorProfile: null,
  };

  async componentDidMount() {
    this.getExploreVendorProfile();
  }

  getExploreVendorProfile = async () => {
    window.scrollTo(0, 0);
    try {
      let vendorProfile = await VendorService.getExploreVendorProfile();
      if (vendorProfile.success) {
        var item = {
          data: vendorProfile.data[
            Math.floor(Math.random() * vendorProfile.data.length)
          ],
        };
        // var item = {
        //   data: vendorProfile.data[40],
        // };
        console.log('item: ', item);
        this.setState({ vendorProfile: item });
      }
    } catch (err) {}
  };

  //render method
  render() {
    const { vendorProfile } = this.state;
    return (
      <>
        <HeaderDocumentDetail title="Event Massi | Explore" />
        {vendorProfile == null ? (
          <div
            className="col-lg-12"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Rings
              height="200"
              width="200"
              radius="9"
              color="#edb300"
              ariaLabel="three-dots-loading"
            />
          </div>
        ) : null}
        {vendorProfile?.data ? (
          <ExploreVendorDetail
            vendorProfile={this.state.vendorProfile}
            loginFormShow={(value) => this.props.loginFormShow(value)}
          />
        ) : null}
      </>
    );
  }
}

export default withRouter(ExploreVendor);
