import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactTooltip from "react-tooltip";
import {
  EmailShareButton,
  FacebookShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TumblrShareButton,
  TwitterShareButton,
} from "react-share";
import {
  EmailIcon,
  FacebookIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  PinterestIcon,
  RedditIcon,
  TumblrIcon,
  TwitterIcon,
} from "react-share";

class SocialShareVendor extends React.Component {
  state = {};
  render() {
    const {
      url,
      title,
      description,
      image,
      keywords,
      showShareModal,
      closeBtn,
    } = this.props;
    return (
      <>
        {/* Share popup modal */}
        <Modal
          isOpen={showShareModal}
          className="sidebar-lg"
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader className="mb-1" tag="div" close={closeBtn}>
            <h4>Share with others</h4>
          </ModalHeader>
          <ModalBody className="text-center padding-top-20 padding-bottom-20">
            <FacebookShareButton
              url={url}
              quote={title}
              description={description}
              data-tip={"Facebook share"}
              className="margin-right-5"
            >
              <FacebookIcon size={32} round />
            </FacebookShareButton>
            <TwitterShareButton
              title={title}
              url={url}
              hashtags={keywords}
              data-tip={"Twitter share"}
              className="margin-right-5"
            >
              <TwitterIcon size={32} round />
            </TwitterShareButton>
            <LinkedinShareButton
              url={url}
              data-tip={"Linkedin share"}
              className="margin-right-5"
            >
              <LinkedinIcon size={32} round />
            </LinkedinShareButton>
            <PinterestShareButton
              url={url}
              media={image}
              data-tip={"Pinterest share"}
              className="margin-right-5"
            >
              <PinterestIcon size={32} round />
            </PinterestShareButton>
            <RedditShareButton
              url={url}
              title={title}
              windowWidth={660}
              windowHeight={460}
              data-tip={"Reddit share"}
              className="margin-right-5"
            >
              <RedditIcon size={32} round />
            </RedditShareButton>
            <TumblrShareButton
              url={url}
              title={title}
              data-tip={"Tumblr share"}
              className="margin-right-5"
            >
              <TumblrIcon size={32} round />
            </TumblrShareButton>
            <EmailShareButton
              url={url}
              subject={title}
              body={description}
              data-tip={"Email share"}
              className="margin-right-5"
            >
              <EmailIcon size={32} round />
            </EmailShareButton>
            <LineShareButton
              url={url}
              title={title}
              data-tip={"Line share"}
              className="margin-right-5"
            >
              <LineIcon size={32} round />
            </LineShareButton>
            <InstapaperShareButton
              url={url}
              title={title}
              data-tip={"Instapaper share"}
              className="margin-right-5"
            >
              <InstapaperIcon size={32} round />
            </InstapaperShareButton>
          </ModalBody>
          <ReactTooltip place={"bottom"} />
        </Modal>
        {/* Share poup modal end */}
      </>
    );
  }
}

export default SocialShareVendor;
