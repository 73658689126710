import React from "react";
import Moment from "react-moment";
class UserMessageContent extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className="message-content">
        <div
          className="dashboard-list-box margin-top-0"
          style={{ boxShadow: "none" }}
        >
          <ul>
            <li className="pending-booking">
              <div className="list-box-listing bookings">
                <div className="list-box-listing-img">
                  <img
                    src="../images/contact_msg_img.png"
                    alt={data.name}
                    style={{ height: "100%" }}
                  />
                </div>
                <div className="list-box-listing-content">
                  <div className="inner">
                    <h3>
                      {data.name}{" "}
                      {data.email !== "" || data.email !== null ? (
                        <span className="booking-status pending">{data.email}</span>
                      ) : null}
                    </h3>

                    <div className="inner-booking-list">
                      <h5>Event Date:</h5>{" "}
                      <ul className="booking-list">
                        <li className="highlighted">
                          <Moment format="DD MMMM YYYY, dddd">
                            {data.desiredDate}
                          </Moment>
                        </li>
                      </ul>
                    </div>

                    <div className="inner-booking-list">
                      <h5>Booking Details:</h5>{" "}
                      <ul className="booking-list">
                        <li className="highlighted">
                          {data.guestCount}{" "}
                          {data.guestCount > 1 ? "guests" : "guest"}
                        </li>
                      </ul>
                    </div>
                    <div className="inner-booking-list">
                      <h5>Client:</h5>{" "}
                      <ul className="booking-list">
                        <li>{data.email}</li>
                        {data.preferredContact.indexOf("phone") === 1 ? (
                          <li>{data.phone}</li>
                        ) : null}
                      </ul>
                    </div>
                    <div className="inner-booking-list">
                      <h5>Contact preferred ways:</h5>{" "}
                      <ul className="booking-list">
                        {data.preferredContact.map((preffered) => {
                          return (
                            <li style={{textTransform: "capitalize"}} key={preffered}>{preffered}</li>
                          )
                        })}
                      </ul>
                    </div>
                    <div className="inner-booking-list">
                      <h5>Message:</h5>{" "}
                      <ul className="booking-list">
                        <li>{data.message}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <a
                  href="#!"
                  className="button gray reject"
                  onClick={() => this.props.confirmDeleteMessage(data.id)}
                >
                  <i className="sl sl-icon-trash"></i> Delete
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default UserMessageContent;
