// import React from 'react';
// import { ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
// import CardSection from './CardSection';
// import axios from 'axios';
// import AuthService from '../services/AuthService';
// class CheckoutForm extends React.Component {
//   handleSubmit = async (event) => {
//     let token = AuthService.getToken();
//     event.preventDefault();

//     const { stripe, elements } = this.props;
//     if (!stripe || !elements) {
//       return;
//     }

//     const card = elements.getElement(CardElement);
//     const result = await stripe.createToken(card);
//     if (result.error) {
//       console.log(result.error.message);
//     } else {
//       console.log(result.token);
//       axios
//         .post(
//           'https://api.eventmassi.com/api/v2/payment-intent',

//           {
//             paymentData: {
//               // token: result.token,
//               name: 'Monthly',
//               currency: 'USD',
//               price_id: 'price_1I9iTKL4WzBwsDIqF24AIKlq',
//               product_id: 'prod_IlExq7kAKk9Vjz',
//               _id: '628332a5e5699073141806b1',
//               price: '14.99',
//             },
//           },
//           {
//             headers: {
//               Authorization: `Bearer ` + token,
//             },
//           }
//         )
//         .then((resp) => {
//           alert('Your payment was successful', resp);
//         })
//         .catch((err) => {
//           console.log(err);
//         });
//     }
//   };

//   render() {
//     return (
//       <div>
//         <form onSubmit={this.handleSubmit}>
//           <CardSection />
//           <button disabled={!this.props.stripe} className="btn-pay">
//             Pay{' '}
//           </button>
//         </form>
//       </div>
//     );
//   }
// }

// export default function InjectedCheckoutForm() {
//   return (
//     <ElementsConsumer>
//       {({ stripe, elements }) => (
//         <CheckoutForm stripe={stripe} elements={elements} />
//       )}
//     </ElementsConsumer>
//   );
// }

import React, { useState } from 'react';
import {
  useStripe,
  useElements,
  CardElement,
  Elements,
  PaymentElement,
} from '@stripe/react-stripe-js';

import CardSection from './CardSection';
import { useNavigate } from 'react-router-dom';
import './CardSectionStyles.css';

export default function CheckoutForm(props) {
  const navigate = useNavigate();
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState(props.clientSecret);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log(clientSecret);
    if (!stripe || !elements) {
      return;
    }
    // const card = elements.getElement(CardElement);
    // const create = await stripe.createToken(card);
    // if (create.error) {
    //   console.log(create.error.message);
    // } else {
    //   console.log(create.token);
    // }

    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      setError(`Payment failed ${result.error.message}`);
      setProcessing(false);
      console.log(result.error.message);
    } else {
      if (result.paymentIntent.status === 'succeeded') {
        setError(null);
        setProcessing(false);
        setSucceeded(true);

        navigate('/completion');
        // Show a success message to your customer
      }
    }
  };
  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : '');
  };

  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        {' '}
        <CardElement
          id="card-element"
          options={{}}
          onChange={handleChange}
        />{' '}
        <h6>
          By providing your card information, you allow Event Massi LLC to
          charge your card for future payments in accordance with their terms.
        </h6>
        <button
          className="btn-pay mt-2"
          disabled={processing || disabled || succeeded}
          id="submit"
        >
          {' '}
          <span id="button-text">
            {' '}
            {processing ? (
              <div className="spinner " id="spinner"></div>
            ) : (
              'Pay'
            )}{' '}
          </span>{' '}
        </button>
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
        <p className={succeeded ? 'result-message' : 'result-message hidden'}>
          Payment succeeded!
        </p>
      </form>
      {/* <form onSubmit={handleSubmit}>
        <CardSection />
        <h6>
          By providing your card information, you allow Event Massi LLC to
          charge your card for future payments in accordance with their terms.
        </h6>
        <button className="btn-pay ">Pay Now</button>
      </form> */}
    </>
  );
}
