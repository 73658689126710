import React from 'react';
import { Link } from 'react-router-dom';
import { URLS, Local_routes } from '../util/constants';

class MassiNormalUserList extends React.Component {
  render() {
    const { vendorList, dataFetch } = this.props;
    return (
      <>
        {dataFetch && vendorList.length > 0 ? (
          <section className="listings-container margin-top-30">
            <div className="row fs-listings">
              {vendorList.map((vendor) => {
                return (
                  <>
                    <div
                      className="col-lg-3 col-md-3 col-sm-6 normal-vendor-list-item"
                      key={vendor.id}
                    >
                      <Link
                        to={Local_routes.vendor_detail + '/' + btoa(vendor.id)}
                        className="listing-item-container border-0"
                      >
                        <div className="listing-item">
                          {vendor.pic_path === '' ||
                          vendor.pic_path === null ||
                          vendor.pic_path === 'undefined' ? (
                            <img
                              src="./images/default.jpeg"
                              alt={vendor.business}
                            />
                          ) : (
                            <img
                              src={`${URLS.S3BucketURL}${vendor.pic_path}`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = './images/default.jpeg';
                              }}
                              alt={'User Profile pic'}
                            />
                          )}
                        </div>
                        <div className="star-rating normal-vendor-list">
                          <div className="rating-counter">
                            {vendor.business}
                            <br></br>
                            <span>
                              {vendor.costly === '1D'
                                ? '$'
                                : vendor.costly === '2D'
                                ? '$$'
                                : vendor.costly === '3D'
                                ? '$$$'
                                : null}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </>
                );
              })}
            </div>
          </section>
        ) : null}
      </>
    );
  }
}

export default MassiNormalUserList;
