import { ApiRoute , CONSTANTS } from "../util/constants";
export default {
  getCategories,
};

async function getCategories() {
  let response = await fetch(`${ApiRoute.getCategories}`, {
    method: "GET",
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load categories: ${response}`);
}

