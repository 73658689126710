import React from 'react';
import Moment from 'react-moment';
class MessageUserList extends React.Component {
  render() {
    const { data, selectedUserMessage } = this.props;
    return (
      <ul>
        {data.map((message) => {
          return (
            <li
              className={`${
                selectedUserMessage?.id === message.id ? 'active-message' : ''
              }`}
              key={message.id}
            >
              <a href="#!" onClick={() => this.props.showMessage(message)}>
                <div className="message-avatar">
                  <img src="../images/contact_msg_img.png" alt={message.name} />
                </div>

                <div className="message-by">
                  <div className="message-by-headline">
                    <h5>{message.name}</h5>
                    <span>
                      <Moment fromNow>{message.createdAt}</Moment>
                    </span>
                  </div>
                  <p>{message.email}</p>
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default MessageUserList;
