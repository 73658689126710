import React from 'react';
import VendorProfileGallery from './VendorProfileGallery';
import ReactTooltip from 'react-tooltip';
import SocialShareVendor from './SocialShareVendor';
import ContactVendorFormModal from './ContactVendorFormModal';
import { URLS } from '../util/constants';
import { Slide } from 'react-slideshow-image';
const buttonStyle = {
  width: '30px',
  background: 'none',
  border: '0px',
  color: '#fff',
  fontWeight: 'bold',
};
const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-left" />
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-right" />
  ),
};
class ExploreVendorDetail extends React.Component {
  state = {
    showContactForm: false,
    showShareModal: false,
  };

  componentDidMount() {}

  componentWillUnmount() {}

  closeBtn = () => (
    <i
      className="fa fa-close cursor-pointer"
      size={15}
      onClick={() =>
        this.setState({ showContactForm: false, showShareModal: false })
      }
    />
  );

  render() {
    const { vendorProfile } = this.props;
    return (
      <div className="bg-dark text-light mt-5">
        <img
          className="bg-dark d-flex mx-auto"
          src="https://www.beta.eventmassi.com/_next/static/media/explore-massi-logo.2eb5f1d1.svg"
          alt=""
        />
        {vendorProfile.data.assets.length > 0 ? (
          <VendorProfileGallery assetsData={vendorProfile.data.assets} />
        ) : null}
        <div className="container vendor-profile-section margin-top-75">
          <div className="row sticky-wrapper">
            <div className="col-lg-12 col-md-12 padding-right-30">
              <div className="col-lg-8">
                <div
                  id="titlebar"
                  className="listing-titlebar"
                  style={{ padding: 0, margin: 0 }}
                >
                  <div className="listing-titlebar-title">
                    <h2>
                      <strong className="text-light">
                        {vendorProfile.data.business}
                      </strong>
                    </h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 sticky">
                <div className="col-md-12 text-center">
                  <button
                    className="button bg-light col-md-6 mt-5"
                    onClick={() =>
                      localStorage.getItem('userToken') === null ||
                      localStorage.getItem('userToken') === undefined
                        ? this.props.loginFormShow(true)
                        : this.setState({ showContactForm: true })
                    }
                  >
                    Book this vendor
                  </button>

                  <div className="listing-share no-border col-md-12 mt-5 mb-5">
                    <ul className="share-buttons margin-top-10 margin-bottom-0">
                      <li>
                        <a
                          href={`tel:${vendorProfile.data.phone}`}
                          data-tip={vendorProfile.data.phone}
                          className="with-tip"
                        >
                          <img
                            src="https://www.beta.eventmassi.com/_next/static/media/call-icon.cdddafa7.svg"
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`mailto:${vendorProfile.data.email}`}
                          data-tip={vendorProfile.data.email}
                        >
                          <img
                            src="https://www.beta.eventmassi.com/_next/static/media/mail.766949b7.svg"
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#!"
                          onClick={() =>
                            this.setState({ showShareModal: true })
                          }
                        >
                          <img
                            src="https://www.beta.eventmassi.com/_next/static/media/share-icon.d83e9d17.svg"
                            alt=""
                          />
                        </a>
                      </li>
                      <ReactTooltip place={'left'} />
                    </ul>
                    <div className="clearfix"></div>
                  </div>
                </div>

                {/* Contact Form vendor */}
                <ContactVendorFormModal
                  showContactForm={this.state.showContactForm}
                  closeBtn={this.closeBtn()}
                  vendorProfile={vendorProfile}
                  closeModal={() => this.setState({ showContactForm: false })}
                />
                {/* Contact form vendor end */}

                {/* Share popup modal */}
                <SocialShareVendor
                  showShareModal={this.state.showShareModal}
                  closeBtn={this.closeBtn()}
                  title={vendorProfile.data.business}
                  url={window.location.href}
                  image={URLS.S3BucketURL + vendorProfile.data.pic_path}
                  description={vendorProfile.data.description}
                  keywords={vendorProfile.data.keywords}
                />
                {/* Share poup modal end */}
              </div>
              {vendorProfile.data.deals.length > 0 ? (
                <div
                  id="listing-deals"
                  className="listing-section margin-bottom-30 "
                >
                  <h3 className="listing-desc-headline">Massi Deals</h3>
                  <ul style={{ listStyle: 'none' }}>
                    {vendorProfile.data.deals.map((deal) => {
                      return (
                        <li key={deal.id}>
                          <div className="list-box-listing">
                            <div className="list-box-listing-img">
                              {deal.assets.length > 0 ? (
                                <Slide autoplay={false} {...properties}>
                                  {deal.assets.map((asset) => {
                                    return (
                                      <a href="#!" key={asset.id}>
                                        <img
                                          src={URLS.S3BucketURL + asset.url}
                                          onError={(e) => {
                                            e.target.onerror = null;
                                            e.target.src =
                                              './images/default.jpeg';
                                          }}
                                          alt={deal.name}
                                        />
                                      </a>
                                    );
                                  })}
                                </Slide>
                              ) : null}
                            </div>
                            <div className="list-box-listing-content">
                              <div className="inner">
                                <h3 className="margin-top-100">{deal.name}</h3>
                                <span>{deal.description}</span>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExploreVendorDetail;
