import React from 'react';
class DeleteMessageFromVendorInbox extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <div className="messages-headline">
        <h4>User Messages</h4>
        {data?.id ? (
          <a
            href="#!"
            className="message-action"
            onClick={() => this.props.confirmDeleteMessage(data.id)}
          >
            <i className="sl sl-icon-trash"></i> Delete {data.name}'s
            Conversation
          </a>
        ) : null}
      </div>
    );
  }
}

export default DeleteMessageFromVendorInbox;
