import React from 'react';

const Feed = (props) => {
  const { id, media_type, media_url } = props.feed;
  let post;

  switch (media_type) {
    case 'VIDEO':
      post = (
        <video
          className="d-flex justify-content-center"
          width="60%"
          height="auto"
          src={media_url}
          type="video/mp4"
          controls
          playsinLine
        ></video>
      );
      break;
    case 'IMAGE':
      post = (
        <img
          className="d-flex justify-content-center"
          width="80%"
          height="auto"
          id={id}
          src={media_url}
          alt="img"
        />
      );
      break;
    default:
      post = (
        <img
          className="mt-5"
          width="80%"
          height="auto"
          id={id}
          src={media_url}
          alt="img"
        />
      );
  }

  return (
    <React.Fragment>
      <a
        href="https://www.instagram.com/eventmassi/?hl=en"
        rel="noreferrer"
        target={'_blank'}
        className="d-flex mx-auto justify-content-center"
      >
        {post}
      </a>
    </React.Fragment>
  );
};

export default Feed;
