import React from 'react';
import { Rings } from 'react-loader-spinner';
import VendorTopMessagesHeader from './VendorTopMessagesHeader';
import MessageUserList from './MessageUserList';
import UserMessageContent from './UserMessageContent';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import OnPageErrorMessages from './OnPageErrorMessages';
class VendorMessages extends React.Component {
  state = {
    selectedUserMessage: null,
  };

  showMessage = (message) => {
    this.setState({ selectedUserMessage: message });
  };

  confirmDeleteMessage = async (msgId) => {
    confirmAlert({
      title: 'Confirm to delete this message',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.setState({ selectedUserMessage: null });
            this.props.deleteUserMessage(msgId);
          },
        },
        {
          label: 'No',
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };

  render() {
    const { vendorMessages, networkErrorMessage } = this.props;
    const { selectedUserMessage } = this.state;
    return (
      <>
        {vendorMessages == null ? (
          <div
            className="col-lg-12"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Rings
              height="200"
              width="200"
              radius="9"
              color="#edb300"
              ariaLabel="three-dots-loading"
            />
          </div>
        ) : null}
        {vendorMessages?.success ? (
          <div className="row margin-bottom-25">
            <div className="tabTopBar">
              <p>
                <strong>Subscribe!</strong> to preferred membership &amp;
                enchance your business. To subscribe <a href="#_">Click Here</a>
              </p>
            </div>
            <div className="col-lg-12 col-md-12">
              <div
                className="messages-container margin-top-5"
                style={{ boxShadow: '0 0 3px 0 rgb(0 0 0 / 6%)' }}
              >
                <VendorTopMessagesHeader
                  data={selectedUserMessage}
                  confirmDeleteMessage={(msgId) =>
                    this.confirmDeleteMessage(msgId)
                  }
                />
                {vendorMessages.data.length > 0 ? (
                  <div className="messages-container-inner">
                    <div className="messages-inbox">
                      <MessageUserList
                        data={vendorMessages.data}
                        showMessage={(message) => this.showMessage(message)}
                        selectedUserMessage={selectedUserMessage}
                      />
                    </div>
                    {selectedUserMessage !== null ? (
                      <UserMessageContent
                        data={selectedUserMessage}
                        confirmDeleteMessage={(msgId) =>
                          this.confirmDeleteMessage(msgId)
                        }
                      />
                    ) : null}
                  </div>
                ) : (
                  <div className="padding-25 text-center">
                    No message found in inbox
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
        {networkErrorMessage !== null ? (
          <OnPageErrorMessages networkErrorMessage={networkErrorMessage} />
        ) : null}
      </>
    );
  }
}

export default VendorMessages;
