import React from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { URLS, Local_routes } from '../util/constants';

const buttonStyle = {
  width: '30px',
  background: 'none',
  border: '0px',
  color: '#fff',
  fontWeight: 'bold',
};
const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-left" />
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-right" />
  ),
};
class MassiPreferredUserList extends React.Component {
  htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  render() {
    const { vendorList } = this.props;
    return (
      <>
        {vendorList.length > 0
          ? vendorList.map((vendor) => {
              return (
                <div className="col-lg-6 col-md-12" key={vendor.id}>
                  <div className="listing-item-container list-layout">
                    <Link
                      to={Local_routes.vendor_detail + '/' + btoa(vendor.id)}
                      className="listing-item"
                    >
                      <div className="listing-item-image">
                        {vendor.pic_path.isArray ? (
                          <Fade autoplay={false} {...properties}>
                            <div className="each-fade" key={vendor.id}>
                              <img
                                src={
                                  'https://theutopianyc.com/wp-content/uploads/2018/10/desk_1-e1542890813137-400x314.jpeg'
                                }
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = './images/default.jpeg';
                                }}
                                alt={vendor.business}
                              />
                            </div>
                          </Fade>
                        ) : (
                          <div className="each-fade">
                            {vendor.pic_path === '' ||
                            vendor.pic_path === null ||
                            vendor.pic_path === 'undefined' ? (
                              <img
                                src="./images/default.jpeg"
                                alt={vendor.business}
                              />
                            ) : (
                              <img
                                src={`${URLS.S3BucketURL}${vendor.pic_path}`}
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = './images/default.jpeg';
                                }}
                                alt={'User Profile pic'}
                              />
                            )}
                          </div>
                        )}
                      </div>

                      <div className="listing-item-content">
                        <div className="listing-item-inner">
                          <h3>
                            {vendor.business}{' '}
                            <img
                              src="https://www.beta.eventmassi.com/_next/static/media/silver-star-icon.1d3735b4.svg"
                              alt=""
                              style={{ width: '30px' }}
                            />
                          </h3>

                          {vendor.states.length > 0
                            ? vendor.states.map((state, index) => {
                                return (
                                  <h4 className="location-span" key={state.id}>
                                    {/* <img
                                      style={{ width: '15px' }}
                                      src="https://www.beta.eventmassi.com/_next/static/media/pin.c824f63e.svg"
                                      alt=""
                                    /> */}

                                    {state.name}

                                    {index < vendor.states.length - 1 &&
                                    vendor.states.length > 1
                                      ? ', '
                                      : null}
                                  </h4>
                                );
                              })
                            : null}
                          <div className="star-rating" data-rating="3.5">
                            <div
                              className="rating-counter"
                              style={{
                                color: 'green',
                                fontSize: '30px',
                                fontWeight: 700,
                              }}
                            >
                              {vendor.costly === '1D'
                                ? '$'
                                : vendor.costly === '2D'
                                ? '$$'
                                : vendor.costly === '3D'
                                ? '$$$'
                                : null}
                            </div>
                          </div>
                          {vendor.deals.length > 0 ? (
                            <div className="insideVendorRightSideBtn">
                              <img
                                src="https://www.beta.eventmassi.com/_next/static/media/deal-icon.69c62f10.png"
                                style={{
                                  height: '75px',
                                  width: '45px',
                                  marginLeft: '-0px',
                                }}
                                alt="Deal Icon"
                              />
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </Link>
                    <div className="vendorMetaWrap">
                      <a href="tel:+15167124094" className="vendorMetaBoxWrap">
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/call-icon.cdddafa7.svg"
                          alt=""
                        />
                      </a>
                      <a className="vendorMetaBoxWrap">
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/mail.766949b7.svg"
                          alt=""
                        />
                      </a>
                      <a className="vendorMetaBoxWrap">
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/share-icon.d83e9d17.svg"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
        <div className="vendorSep"></div>
      </>
    );
  }
}

export default MassiPreferredUserList;
