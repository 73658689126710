import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Local_routes } from '../util/constants';
import Header from '../pages/layouts/Header';
import Home from '../pages/home';
import BrowseVendors from '../pages/browsevendors';
import ExploreVendor from '../pages/explorevendor';
import VendorProfilePage from '../pages/vendorprofile';
import Dashboard from '../pages/dashboard';
import ContactUs from '../pages/contactus';
import Footer from '../pages/layouts/Footer';
import Blogs from '../pages/blogs/Blogs';
import Completion from '../pages/Completion/Completion';
import Massi from '../pages/Why Massi/Massi';
import About from '../pages/About us/About';
import Policy from '../pages/Privacy Policy/Policy';

class MainRoutes extends React.Component {
  state = { loginFormShow: false };
  loginFormShow = (value) => {
    this.setState({ loginFormShow: value });
  };

  render() {
    return (
      <>
        <Header
          loginFormShow={this.state.loginFormShow}
          closeLoginModal={() => this.setState({ loginFormShow: false })}
        />
        <Routes>
          <Route path={Local_routes.home} element={<Home />} />
          <Route path={Local_routes.vendors} element={<BrowseVendors />} />
          <Route
            path={Local_routes.vendor_detail + '/:vendorId'}
            element={
              <VendorProfilePage
                loginFormShow={(value) => this.loginFormShow(value)}
              />
            }
          />
          <Route path={Local_routes.dashboard} element={<Dashboard />} />
          <Route path={Local_routes.contact_us} element={<ContactUs />} />
          <Route path={Local_routes.blogs} element={<Blogs />} />
          <Route path={Local_routes.massi} element={<Massi />} />
          <Route path="/completion" element={<Completion />} />
          <Route path={Local_routes.about_us} element={<About />} />
          <Route path={Local_routes.explore} element={<ExploreVendor />} />
          <Route path={Local_routes.policy} element={<Policy />} />
        </Routes>
        <Footer />
      </>
    );
  }
}

export default MainRoutes;
