import { ApiRoute, CONSTANTS } from '../util/constants';
export default {
  getStates,
};

async function getStates() {
  let response = await fetch(`${ApiRoute.getStates}`, {
    method: 'GET',
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not load states: ${response}`);
}
