import React from 'react';
import { Link } from 'react-router-dom';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { URLS, Local_routes } from '../util/constants';
import './home.css';

const buttonStyle = {
  width: '30px',
  background: 'none',
  border: '0px',
  color: '#fff',
  fontWeight: 'bold',
};
const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-left" />
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-right" />
  ),
};
class MassiSelectedUserList extends React.Component {
  state = {
    books: [],
  };

  htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vendorProfile !== null) {
      // navbar = document.getElementById("vendor-detail-navigation");
      // sticky = navbar.offsetTop;
      // console.log(sticky);
      // window.addEventListener("scroll", this.handleSticky);
    }
  }

  render() {
    const { vendorList } = this.props;

    return (
      <>
        {vendorList.length > 0
          ? vendorList.map((vendor) => {
              return (
                <div key={vendor.id}>
                  <div class="vendorSelectContentWrap">
                    <div className="container">
                      <h5 class="cursor-pointer">
                        <span className="vendor-name ">
                          {vendor.business}

                          <img
                            className="mx-4"
                            src="https://www.beta.eventmassi.com/_next/static/media/gold-star-icon.846ea658.svg"
                            alt=""
                            style={{ width: '38px', marginBottom: '5px' }}
                          />
                        </span>
                      </h5>

                      <Link
                        to={Local_routes.vendor_detail + '/' + btoa(vendor.id)}
                        class="vendorInsideContentDetails"
                      >
                        <div class="vendorImgGallery">
                          {vendor.pic_path.isArray ? (
                            <div class="insideVendorGalleryWrap">
                              <img
                                height="144"
                                width="144"
                                src="https://massi-bucket.s3.amazonaws.com/undefined"
                                alt=""
                              />
                            </div>
                          ) : (
                            <img
                              height="124"
                              width="204"
                              src={`${URLS.S3BucketURL}${vendor.pic_path}`}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = './images/default.jpeg';
                              }}
                              alt={vendor.business}
                            />
                          )}
                        </div>

                        <div class="vendorRightSideDetails mx-5">
                          <div class="insideVendorRightSideDetails">
                            <div class="vendorCat">
                              <span class="MainCatLabel">
                                {/* {vendor.categories.title} */}
                              </span>
                              <div class="selectedCat">
                                <div class="selectCatName">
                                  <span>Category</span>
                                </div>
                              </div>
                            </div>
                            <div class="vendorSelectedStateWrap">
                              <div class="vendorlocation">
                                <img
                                  src="https://www.beta.eventmassi.com/_next/static/media/pin.c824f63e.svg"
                                  alt=""
                                />
                                <span>State</span>
                              </div>
                              <div class="selectedStateName">
                                <span>
                                  {vendor.states.length > 0
                                    ? vendor.states.map((state, index) => {
                                        return (
                                          <span
                                            // className="location-span"
                                            key={state.id}
                                          >
                                            {state.name}

                                            {index < vendor.states.length - 1 &&
                                            vendor.states.length > 1
                                              ? ', '
                                              : null}
                                          </span>
                                        );
                                      })
                                    : null}
                                  &nbsp;
                                </span>
                              </div>

                              <div class="vendorPriceTagWithDealIcon">
                                {vendor.deals.length > 0 ? (
                                  <div className="insideVendorRightSideBtn">
                                    <img
                                      src="https://www.beta.eventmassi.com/_next/static/media/deal-icon.69c62f10.png"
                                      style={{ height: '75px', width: '75px' }}
                                      alt="Deal Icon"
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="star-rating1" data-rating="3.5">
                              <div
                                className="rating-counter1"
                                style={{
                                  color: 'green',
                                  fontSize: '30px',
                                  fontWeight: 700,
                                }}
                              >
                                {vendor.costly === '1D'
                                  ? '$'
                                  : vendor.costly === '2D'
                                  ? '$$'
                                  : vendor.costly === '3D'
                                  ? '$$$'
                                  : null}
                              </div>
                            </div>
                          </div>
                          {vendor.description !== null &&
                          vendor.description !== '' ? (
                            <div className="insideVendorRightSideDetails">
                              <div className="vendorShortInfo">
                                <p>{this.htmlDecode(vendor.description)}</p>
                              </div>
                            </div>
                          ) : null}
                        </div>

                        <div class="vendorMetaWrap">
                          <a href="tel:+15167124094" class="vendorMetaBoxWrap">
                            <img
                              src="https://www.beta.eventmassi.com/_next/static/media/call-icon.cdddafa7.svg"
                              alt=""
                            />
                          </a>
                          <a class="vendorMetaBoxWrap">
                            <img
                              src="https://www.beta.eventmassi.com/_next/static/media/mail.766949b7.svg"
                              alt=""
                            />
                          </a>
                          <a class="vendorMetaBoxWrap">
                            <img
                              src="https://www.beta.eventmassi.com/_next/static/media/share-icon.d83e9d17.svg"
                              alt=""
                            />
                          </a>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </>
    );
  }
}

export default MassiSelectedUserList;
