import React from 'react';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';
import VendorService from '../../services/VendorService';
import CategoriesService from '../../services/CategoriesService';
import StateService from '../../services/StateService';

import VendorSearchForm from '../../components/VendorSearchForm';
import MassiSelectedUserList from '../../components/MassiSelectedUserList';
import MassiNormalUserList from '../../components/MassiNormalUserList';
import BottomPagination from '../../components/BottomPagination';
import { Rings } from 'react-loader-spinner';
import MassiPreferredUserList from '../../components/MassiPreferredUserList';
import '../layouts/layout.css';

class BrowseVendors extends React.Component {
  state = {
    vendorList: [],
    massiPrefferedVendorList: [],
    massiSelectedVendorList: [],
    massiNormalVendorList: [],
    categoriesList: [],
    statesList: [],
    currentPage: 1,
    dataFetch: false,
    totalPages: 1,
  };

  async componentDidMount() {
    let categorieslist = await this.getCategoriesList();
    let statelist = await this.getStateList();
    await this.getVendorList();
    this.setState({ categoriesList: categorieslist });
    this.setState({ statesList: statelist });
  }

  getVendorList = async (page = 1, search = null) => {
    if (search !== null) this.setState({ vendorList: [], dataFetch: false });
    let vendorlist = await VendorService.getBrowseVendorList(page, search);
    if (vendorlist?.users.length > 0) {
      var massiSelectedVendorList = [];
      var massiPrefferedVendorList = [];
      var massiNormalVendorList = [];
      vendorlist?.users.map((vendor) => {
        return vendor.position === 0
          ? massiSelectedVendorList.push(vendor)
          : vendor.position === 1
          ? massiPrefferedVendorList.push(vendor)
          : massiNormalVendorList.push(vendor);
      });
      this.setState({
        vendorList: massiNormalVendorList,
        massiSelectedVendorList: massiSelectedVendorList,
        massiPrefferedVendorList: massiPrefferedVendorList,
        dataFetch: true,
        totalPages: vendorlist.pagination.totalPages,
      });
    } else {
      this.setState({ vendorList: [] });
    }
  };

  getCategoriesList = async () => {
    let categorieslist = await CategoriesService.getCategories();
    return categorieslist;
  };

  getStateList = async () => {
    let statelist = await StateService.getStates();
    return statelist;
  };

  handlePagination = async (page) => {
    await this.setState({ vendorList: [], dataFetch: false });
    await this.setState({ currentPage: page });
    window.scrollTo(0, 0);
    await this.getVendorList(page);
  };

  //render method
  render() {
    return (
      <>
        <HeaderDocumentDetail title="Event Massi | Vendor List" />

        {/* <!-- Titlebar ================================================== --> */}
        {/* <PageTitleBar title="Browse vendors for your next event" /> */}

        <div className="container">
          <h2 className="Browse">Browse vendors for your next event</h2>
        </div>
        <hr></hr>
        <section className="margin-top-15">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <VendorSearchForm
                  categoriesList={this.state.categoriesList}
                  statesList={this.state.statesList}
                  getVendorList={(search) =>
                    this.getVendorList(this.state.currentPage, search)
                  }
                />
              </div>
            </div>
          </div>
        </section>

        <section className="margin-top-15">
          <hr></hr>
          <div className="container">
            {!this.state.dataFetch ? (
              <div
                className="col-lg-12"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Rings
                  height="200"
                  width="200"
                  radius="9"
                  color="#edb300"
                  ariaLabel="three-dots-loading"
                />
              </div>
            ) : (
              <>
                {this.state.massiSelectedVendorList.length > 0 ? (
                  <section className="listing-item-container margin-bottom-15">
                    <div className="row">
                      <div className="col-lg-12 ">
                        <h2 className="selectedHeadingYellow mt-4">
                          Massi Select
                        </h2>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-12">
                        <MassiSelectedUserList
                          vendorList={this.state.massiSelectedVendorList}
                        />
                      </div>
                    </div>
                  </section>
                ) : null}

                {this.state.massiPrefferedVendorList.length > 0 ? (
                  <section className="listings-container margin-bottom-15">
                    <div className="row">
                      <div className="col-lg-12">
                        <h2 className="selectedHeadingYellow ">
                          Massi Preferred
                        </h2>
                      </div>
                    </div>
                    {/* test */}

                    <div className="row bg">
                      <div className="col-lg-12 border border-0 ">
                        <MassiPreferredUserList
                          vendorList={this.state.massiPrefferedVendorList}
                        />
                      </div>
                    </div>
                  </section>
                ) : null}

                <div className="row">
                  <div className="col-md-12">
                    <MassiNormalUserList
                      vendorList={this.state.vendorList}
                      dataFetch={this.state.dataFetch}
                    />
                  </div>
                </div>
                {this.state.vendorList.length !== 0 ? (
                  <div className="row">
                    {/*  */}

                    {/*  */}
                    <div className="col-md-12  ">
                      <div className="pagination-container text-dark margin-top-20 margin-bottom-40 ">
                        <nav className="pagination justify-content-center">
                          <BottomPagination
                            currentPage={this.state.currentPage}
                            totalPages={this.state.totalPages}
                            handlePagination={(page) =>
                              this.handlePagination(page)
                            }
                          />
                        </nav>
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
        </section>
      </>
    );
  }
}

export default BrowseVendors;
