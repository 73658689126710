import React from "react";
import { Helmet } from "react-helmet";
class HeaderDocumentDetail extends React.Component {
  render() {
    return (
      <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.title}</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
      </div>
    );
  }
}

export default HeaderDocumentDetail;
