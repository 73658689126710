import { ApiRoute, CONSTANTS } from "../util/constants";
export default {
  subscribeNewsLetter,
  contactUsForm
};

async function subscribeNewsLetter(email) {
  let data = { email };
  let response = await fetch(`${ApiRoute.subscribeNewsletter}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not subscribe: ${response}`);
}

async function contactUsForm(params) {
  let response = await fetch(`${ApiRoute.contactUs}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not submit contact us form: ${response}`);
}