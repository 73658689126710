import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import MainRoutes from './routes/route';
import { Elements } from '@stripe/react-stripe-js';

import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(
  'pk_test_51HEoMOL4WzBwsDIqhzDcfOc4qDatQDXuhp28dcZGXskdCCHC7iQmCZyhTGti4ztAGmN2qAARkZkRgfwCYgUb4Krh00Os0E6Dfa'
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <Router>
    <Elements stripe={stripePromise}>
      <MainRoutes />
    </Elements>
  </Router>
);

reportWebVitals();
