// import { json } from "react-router-dom";
import { ApiRoute, CONSTANTS } from '../util/constants';
import AuthService from './AuthService';
export default {
  getMassiTopPickVendores,
  getBrowseVendorList,
  getVendorProfileById,
  getVendorContactMessages,
  getLoginVendorProfileData,
  deleteVendorContactMessages,
  updateVendorInfo,
  uploadVendorProfileMedia,
  deleteVendorDeal,
  uploadVendorDeal,
  sendContactMessageToVendor,
  getSubscriptionList,
  uploadVendorAssets,
  uploadVendorVideoAssets,
  getExploreVendorProfile,
};

async function getMassiTopPickVendores() {
  const url = `${ApiRoute.massiTopPicks}`;
  let response = await fetch(url, {
    method: 'GET',
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not fetch massi top picks');
}

async function getBrowseVendorList(page, search = null) {
  var url = `${ApiRoute.browseVendors}`;
  if (page != null) url += `?page=${page}`;

  if (search != null) url += search;

  let response = await fetch(url, {
    method: 'GET',
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get vendor list');
}

async function getVendorProfileById(vendorid) {
  var url = `${ApiRoute.vendorProfile}`;
  if (vendorid != null) {
    url += `?id=${vendorid}`;
  } else {
    throw new Error('Vendor required');
  }
  let response = await fetch(url, {
    method: 'GET',
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get vendor profile');
}

async function getVendorContactMessages() {
  let token = AuthService.getToken();
  var url = `${ApiRoute.getVendorContactMessages}`;
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get messages');
}

async function sendContactMessageToVendor(params) {
  let token = AuthService.getToken();
  var url = `${ApiRoute.getVendorContactMessages}`;
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(url, {
    method: 'POST',
    headers: { ...CONSTANTS.REQUEST_HEADERS, authorization: 'Bearer ' + token },
    body: JSON.stringify(params),
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not send messages');
}

async function deleteVendorContactMessages(msgId) {
  let token = AuthService.getToken();
  var url = `${ApiRoute.getVendorContactMessages}` + '/' + msgId;
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(url, {
    method: 'DELETE',
    headers: { authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not delete the message');
}

async function getLoginVendorProfileData() {
  let token = AuthService.getToken();
  var url = `${ApiRoute.getLoginVendorProfile}`;
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get messages');
}

async function updateVendorInfo(params) {
  let token = AuthService.getToken();
  var url = `${ApiRoute.updateVendorProfile}`;
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(url, {
    method: 'PUT',
    headers: { ...CONSTANTS.REQUEST_HEADERS, authorization: 'Bearer ' + token },
    body: JSON.stringify(params),
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not update profile info');
}

async function uploadVendorProfileMedia(file) {
  let token = AuthService.getToken();
  var url = `${ApiRoute.uploadVendorProfileImage}`;
  if (token === null) {
    throw new Error('Token required');
  }
  const formData = new FormData();
  formData.append('image', file);
  let response = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: 'Bearer ' + token,
      Accept: 'application/json, text/plain, */*',
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not upload profile logo');
}

async function deleteVendorDeal(dealid) {
  let token = AuthService.getToken();
  var url = `${ApiRoute.deleteDeal + dealid}`;
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(url, {
    method: 'DELETE',
    headers: { ...CONSTANTS.REQUEST_HEADERS, authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not delete the deal');
}

async function uploadVendorDeal(params) {
  let token = AuthService.getToken();
  var url = `${ApiRoute.createNewDeal}`;
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(url, {
    method: 'POST',
    headers: { ...CONSTANTS.REQUEST_HEADERS, authorization: 'Bearer ' + token },
    body: JSON.stringify(params),
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not create the deal');
}

async function getSubscriptionList() {
  let token = AuthService.getToken();
  var url = `${ApiRoute.getSubscriptionList}`;
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get subscripiton list');
}

async function uploadVendorAssets(file) {
  let token = AuthService.getToken();
  var url = `${ApiRoute.uploadVendorAssets}`;
  if (token === null) {
    throw new Error('Token required');
  }
  const formData = new FormData();
  formData.append('image', file);
  let response = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: 'Bearer ' + token,
      Accept: 'application/json, text/plain, */*',
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not upload assets');
}

async function uploadVendorVideoAssets(file) {
  let token = AuthService.getToken();
  var url = `${ApiRoute.uploadVendorAssets}`;
  if (token === null) {
    throw new Error('Token required');
  }
  const formData = new FormData();
  formData.append('video', file);
  let response = await fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      authorization: 'Bearer ' + token,
      Accept: 'application/json, text/plain, */*',
    },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not upload video assets');
}
async function getExploreVendorProfile() {
  let token = AuthService.getToken();
  var url = `${ApiRoute.exploreVendor}`;
  if (token === null) {
    throw new Error('Token required');
  }
  let response = await fetch(url, {
    method: 'GET',
    headers: { authorization: 'Bearer ' + token },
  });
  response = await response.json();
  if (response) return response;
  throw new Error('Could not get explore list');
}
