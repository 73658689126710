// import React from 'react';
// import '../About us/About.css';
// const Policy = () => {
//   return (
//     <div>
//       <h1 classNameName="Massi text-warning">Www.Eventmassi.com</h1>
//       <div classNameName="container ">
//         <p classNameName="text-center">Privacy Policy</p>
//       </div>
//       <div classNameName="container-fluid p-5  bg-light mt-5">
//         <div classNameName="borderfaq"></div>
//         <h1 classNameName="p-3 mx-auto   col-sm-9 col-lg-12 submission ">
//           SUBMISSION OF REVIEWS, RATINGS, AND COMMENTS
//         </h1>
//         <p classNameName=" about text-wrap  p-3 mx-auto   col-sm-9 col-lg-12">
//           We collect User Generated Content you voluntarily provide as part of
//           our Site and Services submitted through the Site. We may, in our sole
//           discretion, share your User Generated Content, such as your business
//           reviews, with other members or potential members.
//         </p>
//         <p classNameName="about p-3 mx-auto col-sm-9  col-lg-12">
//           If you do not want certain information shared with others, do not
//           submit User Generated Content containing information you do not want
//           shared in this manner.
//         </p>
//         <p classNameName="about p-3 mx-auto col-sm-9 col-lg-12">
//           Once you have submitted User Generated Content, we reserve the right
//           to publish it in any medium for consumption by others.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default Policy;

import React from 'react';

const Policy = () => {
  return (
    <div className="contentContainer">
      <div className="privacyTopBoxWrap">
        <h2 className="privacyTopBoxHeading">www.eventmassi.com</h2>
        <p className="privacyTaglineText">Privacy Policy</p>
      </div>
      <div className="privacyContentWrap">
        <div className="insideGridContainer">
          <h3>submission of Reviews, Ratings, and Comments</h3>
          <p>
            We collect User Generated Content you voluntarily provide as part of
            our Site and Services submitted through the Site. We may, in our
            sole discretion, share your User Generated Content, such as your
            business reviews, with other members or potential members.
          </p>
          <p>
            If you do not want certain information shared with others, do not
            submit User Generated Content containing information you do not want
            shared in this manner.
          </p>
          <p>
            Once you have submitted User Generated Content, we reserve the right
            to publish it in any medium for consumption by others.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Policy;
