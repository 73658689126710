import React from 'react';
import { Helmet } from 'react-helmet';

class VendorImageAssetUploader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  uploadImage = (e) => {
    this.props.uploadVendorAssetImage(e);
  };

  render() {
    return (
      <>
        <div className="row margin-top-20 margin-right-10 margin-left-20">
          <div className="add-listing-section ">
            {/* margin-top-45 */}
            {/* <div className="add-listing-headline">
              <h3>
                <i className="sl sl-icon-picture"></i> Upload Image
              </h3>
            </div> */}
            <div className="submit-section">
              <form action="/file-upload" className="dropzone">
                <img
                  src="../images/upload-photo-icon.c5403a2b.svg"
                  alt="Upload Asset"
                />
                <input
                  type="file"
                  className="upload_deal_image upload_vendor_asset_image"
                  accept="image/*"
                  onChange={(e) => this.uploadImage(e)}
                />
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default VendorImageAssetUploader;
