import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './InstaFeed.css';
import 'react-slideshow-image/dist/styles.css';
import { Slide } from 'react-slideshow-image';
import Feed from './Feed';
import Slider from 'react-slick';
import MagicSliderDots from 'react-magic-slider-dots';
import 'react-magic-slider-dots/dist/magic-dots.css';

const InstaFeed = ({ token, ...props }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,

    slidesToShow: 2,
    slidesToScroll: 2,
    // appendDots: (dots) => {
    //   return <MagicSliderDots dots={dots} numDotsToShow={5} dotWidth={30} />;
    // },
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: true,
        },
      },
    ],
  };

  const [feeds, setFeedsData] = useState([]);
  //use useRef to store the latest value of the prop without firing the effect
  const tokenProp = useRef(token);
  tokenProp.current = token;

  useEffect(() => {
    const abortController = new AbortController();

    async function fetchInstagramPost() {
      try {
        axios
          .get(
            `https://graph.instagram.com/me/media?fields=id,media_type,media_url,caption&access_token=${'IGQVJYOGNUYjlqV043OW1vZA3pqZAHJsT3dsYnozLWFiZADBIanhIS1p6X3c4aDRDTDlMY21USTYtOVVaUGE5ZAkRSTC1rTGFhWXZAMekR4Y2RyU0FlUW5yQlFJMEpwWFdwOXJhLTRVS2p6a01mNXRFTnZAjMwZDZD'}`
          )
          .then((resp) => {
            setFeedsData(resp.data.data);
            console.log(resp.data.data);
          });
      } catch (err) {
        console.log('error', err);
      }
    }
    fetchInstagramPost();

    return () => {
      abortController.abort();
    };
  }, [props.limit]);

  return (
    // <div className="slide-container">
    //   <Slide>
    //     {feeds.map((feed) => (
    //       <Feed key={feed.id} feed={feed} />
    //     ))}
    //   </Slide>
    // </div>
    <div className=" p-3">
      <Slider {...settings}>
        {feeds.map((feed) => (
          <Feed key={feed.id} feed={feed} />
        ))}
      </Slider>
    </div>
  );
};

export default InstaFeed;
