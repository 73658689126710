import React from 'react';
import { URLS } from '../util/constants';
import VendorService from '../services/VendorService';
class UploadVendorLogo extends React.Component {
  state = {
    logoUrl: null,
  };

  componentDidMount() {
    this.setState({ logoUrl: this.props.vendorProfile.pic_path });
  }

  changeHandler = async (event) => {
    let data = await VendorService.uploadVendorProfileMedia(
      event.target.files[0]
    );
    var pic_path = data.imagePath;
    if (data?.imagePath) {
      var params = {
        pic_path,
      };
      this.props.updateVendorInfo(params);
      this.setState({ logoUrl: data.imagePath });
    } else {
    }
  };

  render() {
    return (
      <>
        <div className="container margin-top-50">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="dashboard-list-box margin-top-0"
                style={{ boxShadow: '0 0 3px 0 rgb(0 0 0 / 6%)' }}
              >
                <h4 className="gray">My Vendor Profile Image</h4>

                <div className="dashboard-list-box-static bg-light-gray">
                  <div className="edit-profile-photo">
                    {this.state.logoUrl === '' ? (
                      <img src="images/user-avatar.jpg" alt="Business Logo" />
                    ) : (
                      <img
                        src={URLS.S3BucketURL + this.state.logoUrl}
                        alt="Business Logo"
                      />
                    )}
                    <div className="change-photo-btn">
                      <span>
                        <i className="fa fa-upload"></i> Upload Photo
                      </span>
                      <div className="photoUpload">
                        <input
                          accept="image/*"
                          type="file"
                          className="upload"
                          onChange={(e) => this.changeHandler(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default UploadVendorLogo;
