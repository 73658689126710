import React from 'react';
import Select from 'react-select';
import './home.css';
class VendorSearchForm extends React.Component {
  state = {
    selectedCategory: '',
    selectedState: '',
    selectedCostly: '',
    searchText: '',
  };

  searchSubmit = () => {
    const obj = {
      category: this.state.selectedCategory?.value
        ? this.state.selectedCategory.value
        : '',
      state: this.state.selectedState?.value
        ? this.state.selectedState?.value
        : '',
      costly: this.state.selectedCostly?.value
        ? this.state.selectedCostly?.value
        : '',
      query: this.state.searchText,
    };
    const result = '&' + new URLSearchParams(obj).toString();
    this.props.getVendorList(result);
  };

  resetSearch = () => {
    this.setState({
      selectedCategory: '',
      selectedState: '',
      selectedCostly: '',
      searchText: '',
    });
    this.props.getVendorList();
  };

  render() {
    const { categoriesList, statesList } = this.props;
    const categoriesOptions = [];
    const stateOptions = [];
    if (categoriesList?.success && categoriesList?.data.length > 0) {
      categoriesList.data.map((category) => {
        return categoriesOptions.push({
          value: category.id,
          label: category.title,
        });
      });
    }

    if (statesList?.success && statesList?.data.length > 0) {
      statesList.data.map((state) => {
        return stateOptions.push({ value: state.id, label: state.name });
      });
    }

    const costlyOptions = [
      { value: '1D', label: '$' },
      { value: '2D', label: '$$' },
      { value: '3D', label: '$$$' },
    ];
    return (
      <div className="vendorForm  searchbar p-5  ">
        <div className="main-search-input     margin-top-0 margin-bottom-10">
          <input
            type="text"
            className="vendorSearch  "
            placeholder="Search Here"
            onChange={(e) => this.setState({ searchText: e.target.value })}
            defaultValue={this.state.searchText}
          />

          <Select
            className="vendorSelect1 col-lg-6"
            placeholder="Vendor Category"
            options={categoriesOptions}
            isMulti={false}
            name="select_category"
            noOptionsMessage="No category listed"
            isDisabled={categoriesOptions.length === 0 ? true : false}
            onChange={(option) => this.setState({ selectedCategory: option })}
            value={this.state.selectedCategory}
          />

          <Select
            className="vendorSelect2 col-lg-2  "
            placeholder="State"
            options={stateOptions}
            isMulti={false}
            name="select_state"
            noOptionsMessage="No state listed"
            isDisabled={stateOptions.length === 0 ? true : false}
            onChange={(option) => this.setState({ selectedState: option })}
            value={this.state.selectedState}
          />

          <Select
            className="vendorSelect3 "
            placeholder="$ $$ $$$"
            options={costlyOptions}
            isMulti={false}
            name="select_costly"
            onChange={(option) => this.setState({ selectedCostly: option })}
            value={this.state.selectedCostly}
          />

          {/* <button
            className="button  bg-dark text-light margin-left-10 margin-right-5"
            onClick={() => this.searchSubmit()}
          >
            <i className="fa fa-search"></i>
          </button>{' '} */}
          <button
            className="searchVendorBtn"
            onClick={() => this.searchSubmit()}
          >
            <img
              src="https://www.beta.eventmassi.com/_next/static/media/vendor-search-icon.809a2701.svg"
              alt="searchimage"
            />

            <span>Search</span>
          </button>
        </div>
      </div>
    );
  }
}

export default VendorSearchForm;
