import React from 'react';
import './Completion.css';
const Completion = () => {
  return (
    <div class="payment-confirm">
      <img
        className="mt-3"
        src="https://www.beta.eventmassi.com/_next/static/media/Cash%20Payment-bro.6aa3bdcf.png"
        alt=""
        height="300"
        width="300"
      />
      <h1>Payment succeeded!</h1>
      <a href="/">RETURN HOME</a>
      <span className="margin-bottom-50 ">
        If you have any problems <a href="/contact_us">Contact-us</a>{' '}
      </span>
    </div>
  );
};

export default Completion;
