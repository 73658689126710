import { ApiRoute , CONSTANTS } from "../util/constants";
export default {
  login,
  register,
  getToken
};

async function login(email, password) {
  let data = { email, password };
  let response = await fetch(`${ApiRoute.login}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not login: ${response}`);
}

async function register(email, password) {
  let data = { email, password };
  let response = await fetch(`${ApiRoute.register}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: { ...CONSTANTS.REQUEST_HEADERS },
  });
  response = await response.json();
  if (response) return response;
  throw new Error(`Could not login: ${response}`);
}

function getToken() {
  let token = localStorage.getItem("userToken");
  if (token){
    token = atob(token);
    return token;
  };
  return null;
}
