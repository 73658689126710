import React from 'react';
import { Rings } from 'react-loader-spinner';
import { Slide } from 'react-slideshow-image';
import { URLS } from '../util/constants';
import VendorService from '../services/VendorService';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Moment from 'react-moment';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContent } from '../components/Toast';
import 'react-responsive-modal/styles.css';
// import { Modal } from 'react-responsive-modal';
// import { ModalBody } from 'reactstrap';
import './home.css';
const buttonStyle = {
  width: '30px',
  background: 'none',
  border: '0px',
  color: '#fff',
  fontWeight: 'bold',
};
const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-left" />
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-right" />
  ),
};
class VendorDashboardDeals extends React.Component {
  state = {
    dealsData: null,
    showDealModal: false,
  };

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.dealsData !== prevProps.dealsData) {
      this.setState({ dealsData: this.props.dealsData });
    }
  }

  confirmDeleteMessage = async (id) => {
    confirmAlert({
      title: 'Confirm to delete this deal',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.deleteDeal(id);
          },
        },
        {
          label: 'No',
          onClick: () => {
            return false;
          },
        },
      ],
    });
  };

  deleteDeal = async (id) => {
    try {
      var dealdelete = await VendorService.deleteVendorDeal(id);
      if (dealdelete) {
        const newList = this.state.dealsData.filter((item) => item.id !== id);
        this.setState((this.state.dealsData = newList));
        toast(() => ToastContent('Deal has been deleted successfully'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
      } else {
        toast(() => ToastContent('Error on deleting the deal'), {
          toastId: 'errorToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };
  uploadNewDeal = async (e) => {
    e.preventDefault();
    try {
      var name = e.target.elements.dealname.value;
      var description = e.target.elements.dealdescription.value;
      var dealMedia = await VendorService.uploadVendorProfileMedia(
        this.state.dealImage
      );
      var params = {
        name,
        description,
        assets: { type: 'photo', url: dealMedia.imagePath },
      };
      var uploadDeal = await VendorService.uploadVendorDeal(params);
      if (uploadDeal.success) {
        var oldDeals = this.state.dealsData;
        oldDeals.push(uploadDeal.deal);
        this.setState({ dealsData: oldDeals, showDealModal: false });
        toast(() => ToastContent('Deal has been created successfully'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
      } else {
        toast(() => ToastContent('Deal not created.'), {
          toastId: 'errorToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } catch (err) {
      toast(() => ToastContent('Deal not created.'), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  onChangeDealImage = async (event) => {
    let imagePath = event.target.files[0];
    this.setState({ dealImage: imagePath });
  };
  render() {
    const { dealsData } = this.state;
    return (
      <>
        {dealsData == null ? (
          <div
            className="col-lg-12"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Rings
              height="200"
              width="200"
              radius="9"
              color="#edb300"
              ariaLabel="three-dots-loading"
            />
          </div>
        ) : (
          <>
            <div className="tabTopBar ">
              <p>
                <strong>Subscribe!</strong> to preferred membership &amp;
                enchance your business. To subscribe <a href="#_">Click Here</a>
              </p>
            </div>
            <div className="row margin-top-25 margin-bottom-50">
              <div className="dealsTopContentWrap">
                <h6>Deals &amp; Specials</h6>
                <p>Upload your deals &amp; specials from here</p>
              </div>

              {/*  */}
              <div className="dealsFormWrap">
                <form className="dealsForm" onSubmit={this.uploadNewDeal}>
                  <div className="dealFormGrp">
                    <label className="">Deal Name</label>
                    <input
                      type="text"
                      className="dealFormControl"
                      name="dealname"
                      id="dealname"
                      defaultValue={''}
                      placeholder=""
                    />
                  </div>
                  <div className="dealFormGrp">
                    <label>Description</label>
                    <textarea
                      className="dealFormControl"
                      textareaname="dealdescription"
                      cols="40"
                      rows="3"
                      id="dealdescription"
                      spellCheck="true"
                    ></textarea>
                  </div>
                  <div className="upload-btn-wrapper">
                    <button className="btn">
                      <img
                        src="https://www.beta.eventmassi.com/_next/static/media/img-upload-icon.aa027acc.svg"
                        alt=""
                      />
                    </button>
                    <input
                      type="file"
                      name="myfile"
                      className="upload_deal_image"
                      accept="image/*"
                      onChange={(e) => this.onChangeDealImage(e)}
                    />
                  </div>
                  <div className="dealFormGrp dealBtnGrp">
                    <button className="dealUploadBtn">Upload Now!</button>
                  </div>
                </form>
              </div>
              {/*  */}

              <div className="col-lg-12 col-md-12 col-sm-12">
                {/* <div className="row text-right margin-bottom-25">
                  <button
                    className="button"
                    onClick={() => this.props.showDealModal(true)}
                  >
                    <i className="fa fa-plus"></i>Add New Deal
                  </button>
                </div> */}

                <div
                  className="dashboard-list-box margin-top-20"
                  style={{ boxShadow: '0 0 4px 0 rgb(0 0 0 / 6%)' }}
                >
                  {/* <h4>Your deals</h4> */}
                  <ul>
                    {dealsData.length > 0 ? (
                      dealsData.map((deal) => {
                        return (
                          <li key={deal.id}>
                            <div className="list-box-listing">
                              <div className="list-box-listing-img mt-3">
                                {deal.assets.length > 0 ? (
                                  <Slide autoplay={false} {...properties}>
                                    {deal.assets.map((asset) => {
                                      return (
                                        <a href="#!" key={asset.id}>
                                          <img
                                            src={URLS.S3BucketURL + asset.url}
                                            onError={(e) => {
                                              e.target.onerror = null;
                                              e.target.src =
                                                './images/default.jpeg';
                                            }}
                                            alt={deal.name}
                                          />
                                        </a>
                                      );
                                    })}
                                  </Slide>
                                ) : null}
                              </div>
                              <div className="list-box-listing-content">
                                <div className="inner">
                                  <h3>{deal.name}</h3>
                                  <span>{deal.description}</span>
                                  <div
                                    className="star-rating"
                                    data-rating="5.0"
                                  >
                                    <div className="rating-counter">
                                      <Moment format="DD MMMM YYYY, dddd">
                                        {deal.createdAt}
                                      </Moment>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="buttons-to-right">
                              <a
                                href="#!"
                                // className="button gray"
                                onClick={() =>
                                  this.confirmDeleteMessage(deal.id)
                                }
                              >
                                <img
                                  src="https://www.beta.eventmassi.com/_next/static/media/delete-icon.55f218d8.svg"
                                  alt=""
                                />
                                {/* <i className="sl sl-icon-close"></i> Delete */}
                              </a>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <li>No deal found</li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export default VendorDashboardDeals;
