import React from 'react';
import { URLS } from '../util/constants';
import ImageGallery from 'react-image-gallery';
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import loadjs from 'loadjs';
class VendorProfileGallery extends React.Component {
  state = {
    images: [],
  };
  componentDidMount() {
    // loadjs([
    //   "../scripts/slick.min.js",
    //   "../scripts/magnific-popup.min.js",
    //   "../scripts/custom.js",
    // ]);
    loadjs(['../scripts/slick.min.js', '../scripts/magnific-popup.min.js'], {
      async: true, // load files synchronously or asynchronously (default: true)
      numRetries: 2, // see caveats about using numRetries with async:false (default: 0),
      returnPromise: true, // return Promise object (default: false)
    })
      .then(function () {
        loadjs('../scripts/custom.js');
      })
      .catch(function (pathsNotFound) {
        console.log('hello', pathsNotFound);
      });
    let imagesArray = [];
    this.props.assetsData.map((asset) => {
      let url = '';
      if (asset.path && asset.type === 'image') {
        url = URLS.S3BucketURL + asset.path;
      } else if (asset.url && asset.type === 'image') {
        url = URLS.S3BucketURL + asset.url;
      }
      if (url !== '') imagesArray.push({ original: url, thumbnail: url });

      // if(asset.type === 'video'){
      //   url = asset.path ? URLS.S3BucketURL + asset.path : asset.url ? URLS.S3BucketURL + asset.url : '';
      //   if (url !== "") imagesArray.push({ original: url, thumbnail: url , embedUrl : url });
      // }
    });
    this.setState({ images: imagesArray });
  }

  render() {
    const { assetsData } = this.props;
    return (
      <>
        {this.state.images.length > 0 && assetsData.length > 0 ? (
          <div className="listing-slider mfp-gallery-container margin-bottom-0">
            {this.state.images.map((image) => {
              return (
                <a
                  href={image.original}
                  data-background-image={image.original}
                  className="item mfp-gallery"
                  key={image.original}
                ></a>
              );
            })}
          </div>
        ) : null}
      </>
    );
  }
}

export default VendorProfileGallery;
