import React from 'react';
import { Rings } from 'react-loader-spinner';
import ReactTooltip from 'react-tooltip';
import VendorProfileGallery from './VendorProfileGallery';
import SocialShareVendor from './SocialShareVendor';
import ContactVendorFormModal from './ContactVendorFormModal';
import { URLS } from '../util/constants';
import { Slide } from 'react-slideshow-image';
const buttonStyle = {
  width: '30px',
  background: 'none',
  border: '0px',
  color: '#fff',
  fontWeight: 'bold',
};
const properties = {
  prevArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-left" />
  ),
  nextArrow: (
    <button style={{ ...buttonStyle }} className="sl sl-icon-arrow-right" />
  ),
};
let navbar;
let sticky;
class VendorDetail extends React.Component {
  state = {
    showContactForm: false,
    showShareModal: false,
  };
  htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent;
  };

  closeBtn = () => (
    <i
      className="fa fa-close cursor-pointer"
      size={15}
      onClick={() =>
        this.setState({ showContactForm: false, showShareModal: false })
      }
    />
  );

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {
    if (this.props.vendorProfile !== null) {
      // navbar = document.getElementById("vendor-detail-navigation");
      // sticky = navbar.offsetTop;
      // console.log(sticky);
      // window.addEventListener("scroll", this.handleSticky);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleSticky);
  }

  handleSticky = () => {
    console.log(window.scrollY, sticky);
    if (window.scrollY > sticky) {
      navbar.classList.add('sticky');
    } else {
      navbar.classList.remove('sticky');
    }
  };

  render() {
    const { vendorProfile } = this.props;
    return (
      <>
        {vendorProfile == null ? (
          <div
            className="col-lg-12"
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Rings
              height="200"
              width="200"
              radius="9"
              color="#edb300"
              ariaLabel="three-dots-loading"
            />
          </div>
        ) : null}

        {vendorProfile != null ? (
          <>
            <hr></hr>
            <div className="listing-titlebar-title mx-1">
              <h2>
                <strong>{vendorProfile.data.business}</strong>
              </h2>
            </div>

            {/*  */}
            <div className="mobileSearchForm">
              <div className="col-lg-4 col-md-4  margin-top-35 sticky ">
                <div className="col-md-12 text-center">
                  <button
                    className=" book-vendor col-md-12"
                    onClick={() =>
                      // localStorage.getItem('userToken') === null ||
                      // localStorage.getItem('userToken') === undefined
                      // ? this.props.loginFormShow(true)
                      this.setState({ showContactForm: true })
                    }
                  >
                    Book this vendor
                  </button>
                  <div className="listing-share no-border col-md-12">
                    <ul className="share-buttons d-flex   margin-top-10 margin-bottom-20">
                      <li>
                        <a
                          href={`tel:${vendorProfile.data.phone}`}
                          data-tip={vendorProfile.data.phone}
                          className="with-tip"
                        >
                          <img
                            src="https://www.beta.eventmassi.com/_next/static/media/call-icon.cdddafa7.svg"
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href={`mailto:${vendorProfile.data.email}`}
                          data-tip={vendorProfile.data.email}
                        >
                          <img
                            src="https://www.beta.eventmassi.com/_next/static/media/mail.766949b7.svg"
                            alt=""
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="#!"
                          onClick={() =>
                            this.setState({ showShareModal: true })
                          }
                        >
                          <img
                            src="https://www.beta.eventmassi.com/_next/static/media/share-icon.d83e9d17.svg"
                            alt=""
                          />
                        </a>
                      </li>
                      <ReactTooltip place={'left'} />
                    </ul>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
            {/*  */}

            <div className="clearfix"></div>

            <VendorProfileGallery assetsData={vendorProfile.data.assets} />
            {vendorProfile.data.business != null ? (
              <div className="container vendor-profile-section">
                <div className="row sticky-wrapper">
                  <div className="col-lg-8 col-md-8 padding-right-30">
                    {/* <div id="titlebar" className="listing-titlebar">
                      <div className="listing-titlebar-title">
                        <h2>
                          <strong>{vendorProfile.data.business}</strong>
                        </h2>
                      </div>
                    </div> */}
                    {/* <!-- Listing Nav --> */}
                    {/* <div
                      id="vendor-detail-navigation"
                      className="listing-nav-container"
                    >
                      <ul className="listing-nav vendor-profile-nav">
                        <li>
                          <a href="#listing-overview" className="active">
                            Overview
                          </a>
                        </li>

                        <li>
                          <a href="#listing-description">Description</a>
                        </li>
                        <li>
                          <a href="#listing-keywords">Keywords</a>
                        </li>
                        <li>
                          <a href="#listing-pricing">Pricing</a>
                        </li>
                        <li>
                          <a href="#listing-deals">Massi Deals</a>
                        </li>
                      </ul>
                    </div> */}
                    {/* <!-- Overview --> */}

                    <div
                      id="listing-overview"
                      className="listing-section border-bottom"
                    >
                      {/* Categories section */}
                      {vendorProfile.data.categories.length > 0 ? (
                        <>
                          <div className="vendorCat">
                            <div className="selectedCat">
                              <span className="MainCatLabel">Category</span>
                              <div className="selectCatName">
                                {vendorProfile.data.categories.map(
                                  (category) => {
                                    return <span>{category.title}</span>;
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      {/* Keywords section start */}

                      <div className="vendorKeywords mt-4">
                        <span
                          id="#listing-keywords"
                          className="MainKeywordLabel"
                        >
                          Keywords
                        </span>
                        <div className="selectedKeywords">
                          <div className="selectKeywordName">
                            {vendorProfile.data.keywords.length > 0 ? (
                              <>
                                {vendorProfile.data.keywords.map((keyword) => {
                                  return (
                                    <span key={keyword.id}>{keyword.name}</span>
                                  );
                                })}
                              </>
                            ) : null}
                          </div>
                        </div>
                        {/* Keywords section end */}
                        {vendorProfile.data.states.length > 0 ? (
                          <>
                            <section className="margin-bottom-30 margin-top-45">
                              <h3 className="listing-desc-headline margin-bottom-10">
                                <img
                                  src="https://www.beta.eventmassi.com/_next/static/media/pin.c824f63e.svg"
                                  alt=""
                                />
                                <strong className="state ">State</strong>
                                {vendorProfile.data.states.map(
                                  (state, index) => {
                                    return (
                                      <span className="listing-address text-dark margin-left-20">
                                        {state.name}
                                        {index <
                                          vendorProfile.data.states.length -
                                            1 &&
                                        vendorProfile.data.states.length > 1
                                          ? ', '
                                          : null}
                                      </span>
                                    );
                                  }
                                )}
                              </h3>
                              {vendorProfile.data.willingToTravel ? (
                                <section className="margin-bottom-30">
                                  <h3 className="listing-desc-headline margin-bottom-10">
                                    <strong>
                                      <i className="fa fa-plane margin-right-5 mt-4"></i>
                                      Willing to travel
                                    </strong>
                                  </h3>
                                </section>
                              ) : null}
                              <div className="vendorPriceTag">
                                <span> $$ </span>
                                <img
                                  className="imaaage"
                                  src="https://www.beta.eventmassi.com/_next/static/media/deal-icon.69c62f10.png"
                                  alt=""
                                />
                              </div>
                              <div className="vendorSep margin-top-20"></div>
                            </section>
                          </>
                        ) : null}

                        {vendorProfile.data?.socials &&
                        (vendorProfile.data?.socials.facebook ||
                          vendorProfile.data?.socials.instagram ||
                          vendorProfile.data?.socials.linkedIn ||
                          vendorProfile.data?.socials.pinterest ||
                          vendorProfile.data?.socials.tiktok ||
                          vendorProfile.data?.socials.twitter ||
                          vendorProfile.data?.socials.youtube ||
                          vendorProfile.data?.socials?.website) ? (
                          <section>
                            <strong className="state">
                              <i className="fa fa-globe margin-right-5"></i>
                              Website
                            </strong>
                            <h3 className="listing-desc-headline margin-top-10">
                              <ul className="social-icons">
                                {/* {vendorProfile.data?.socials.website ? (
                                <li>
                                  <a
                                    href={vendorProfile.data?.socials.website}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img
                                      src="../images/website.png"
                                      alt="Website link"
                                    />
                                  </a>
                                </li>
                              ) : null} */}
                                {vendorProfile.data?.socials.facebook ? (
                                  <li>
                                    <a
                                      href={
                                        vendorProfile.data?.socials.facebook
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src="../images/facebook.png"
                                        alt="Facebook profile"
                                      />
                                    </a>
                                  </li>
                                ) : null}
                                {vendorProfile.data?.socials.instagram ? (
                                  <li>
                                    <a
                                      href={
                                        vendorProfile.data?.socials.instagram
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src="../images/instagram.png"
                                        alt="Instagram"
                                      />
                                    </a>
                                  </li>
                                ) : null}
                                {vendorProfile.data?.socials.linkedIn ? (
                                  <li>
                                    <a
                                      href={
                                        vendorProfile.data?.socials.linkedIn
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src="../images/linkedIn.png"
                                        alt="Linkedin"
                                      />
                                    </a>
                                  </li>
                                ) : null}
                                {vendorProfile.data?.socials.pinterest ? (
                                  <li>
                                    <a
                                      href={
                                        vendorProfile.data?.socials.pinterest
                                      }
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src="../images/pinterest.png"
                                        alt="Pinterest"
                                      />
                                    </a>
                                  </li>
                                ) : null}
                                {vendorProfile.data?.socials.tiktok ? (
                                  <li>
                                    <a
                                      href={vendorProfile.data?.socials.tiktok}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src="../images/tiktok.png"
                                        alt="TikTok"
                                      />
                                    </a>
                                  </li>
                                ) : null}
                                {vendorProfile.data?.socials.twitter ? (
                                  <li>
                                    <a
                                      href={vendorProfile.data?.socials.twitter}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src="../images/twitter.png"
                                        alt="Twitter"
                                      />
                                    </a>
                                  </li>
                                ) : null}
                                {vendorProfile.data?.socials.youtube ? (
                                  <li>
                                    <a
                                      href={vendorProfile.data?.socials.youtube}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <img
                                        src="../images/youtube.png"
                                        alt="Youtube"
                                      />
                                    </a>
                                  </li>
                                ) : null}
                              </ul>
                            </h3>
                            <div class="vendorSep"></div>
                          </section>
                        ) : null}
                      </div>
                    </div>
                    {/* Description section */}
                    {vendorProfile.data.description ? (
                      <div
                        id="listing-description"
                        className="listing-section margin-top-30 margin-bottom-30"
                      >
                        <h3 className="description">Description</h3>
                        <p>{this.htmlDecode(vendorProfile.data.description)}</p>

                        <div className="clearfix"></div>
                      </div>
                    ) : null}
                    {/* Description section end */}

                    {/* Price section */}
                    <div
                      id="listing-pricing"
                      className="listing-section margin-bottom-30"
                    >
                      <h3 className="description">Pricing</h3>
                      <div
                        className="rating-counter"
                        style={{
                          color: 'green',
                          fontSize: '30px',
                          fontWeight: 700,
                        }}
                      >
                        {vendorProfile.data.costly === '1D'
                          ? '$'
                          : vendorProfile.data.costly === '2D'
                          ? '$$'
                          : vendorProfile.data.costly === '3D'
                          ? '$$$'
                          : null}
                      </div>
                    </div>
                    {/* price section */}
                    {/* Massi Deal */}
                    {vendorProfile.deals.length > 0 ? (
                      <div
                        id="listing-deals"
                        className="listing-section margin-bottom-30 "
                      >
                        {/* <h3 className="listing-desc-headline">Massi Deals</h3> */}
                        <img
                          className="border-bottom   border-3 border-warning"
                          src="https://www.beta.eventmassi.com/_next/static/media/massi-deals-heading.3f401064.svg"
                          alt=""
                        />

                        <ul style={{ listStyle: 'none' }}>
                          {vendorProfile.deals.map((deal) => {
                            return (
                              <li key={deal.id}>
                                <div id="deal" className="vendorDeals">
                                  <img
                                    src="/_next/static/media/massi-deals-heading.3f401064.svg"
                                    alt=""
                                  />

                                  <div className="dealBoxWrap">
                                    {/* <div class="dealContentDetails"> */}
                                    <h3 className="margin-left-70">
                                      {deal.name}
                                    </h3>
                                    <span className="margin-left-70">
                                      {deal.description}
                                    </span>
                                  </div>
                                </div>
                                {/* </div> */}
                                {/* <div className="list-box-listing">
                                  <div className="list-box-listing-img">
                                    {deal.assets.length > 0 ? (
                                      <Slide autoplay={false} {...properties}>
                                        {deal.assets.map((asset) => {
                                          return (
                                            <a href="#!" key={asset.id}>
                                              <img
                                                src={
                                                  URLS.S3BucketURL + asset.url
                                                }
                                                onError={(e) => {
                                                  e.target.onerror = null;
                                                  e.target.src =
                                                    './images/default.jpeg';
                                                }}
                                                alt={deal.name}
                                              />
                                            </a>
                                          );
                                        })}
                                      </Slide>
                                    ) : null}
                                  </div> */}
                                {/* <div className="list-box-listing-content">
                                    <div className="inner"></div>
                                  </div> */}
                                {/* </div> */}
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                    {/* Massi deal end */}
                  </div>

                  <div className="col-lg-4 col-md-4 margin-top-35 sticky">
                    <div className="col-md-12 text-center">
                      <button
                        className=" book-vendor2 col-md-12"
                        onClick={() =>
                          // localStorage.getItem('userToken') === null ||
                          // localStorage.getItem('userToken') === undefined
                          // ? this.props.loginFormShow(true)
                          this.setState({ showContactForm: true })
                        }
                      >
                        Book this vendor
                      </button>
                      <div className="listing-share2 no-border col-md-12">
                        <ul className="share-buttons  d-flex  margin-left-30 margin-top-10 margin-bottom-20">
                          <li>
                            <a
                              href={`tel:${vendorProfile.data.phone}`}
                              data-tip={vendorProfile.data.phone}
                              className="with-tip"
                            >
                              <img
                                src="https://www.beta.eventmassi.com/_next/static/media/call-icon.cdddafa7.svg"
                                alt=""
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href={`mailto:${vendorProfile.data.email}`}
                              data-tip={vendorProfile.data.email}
                            >
                              <img
                                src="https://www.beta.eventmassi.com/_next/static/media/mail.766949b7.svg"
                                alt=""
                              />
                            </a>
                          </li>
                          <li>
                            <a
                              href="#!"
                              onClick={() =>
                                this.setState({ showShareModal: true })
                              }
                            >
                              <img
                                src="https://www.beta.eventmassi.com/_next/static/media/share-icon.d83e9d17.svg"
                                alt=""
                              />
                            </a>
                          </li>
                          <ReactTooltip place={'left'} />
                        </ul>
                        <div className="clearfix"></div>
                      </div>
                    </div>

                    {/* Contact Form vendor */}
                    <ContactVendorFormModal
                      showContactForm={this.state.showContactForm}
                      closeBtn={this.closeBtn()}
                      vendorProfile={vendorProfile}
                      closeModal={() =>
                        this.setState({ showContactForm: false })
                      }
                    />
                    {/* Contact form vendor end */}

                    {/* Share popup modal */}
                    <SocialShareVendor
                      showShareModal={this.state.showShareModal}
                      closeBtn={this.closeBtn()}
                      title={vendorProfile.data.business}
                      url={window.location.href}
                      image={URLS.S3BucketURL + vendorProfile.data.pic_path}
                      description={vendorProfile.data.description}
                      keywords={vendorProfile.data.keywords}
                    />
                    {/* Share poup modal end */}
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : null}
      </>
    );
  }
}

export default VendorDetail;
