import React, { useState, useEffect } from 'react';
import '../layouts/layout.css';
import axios from 'axios';

const Massi = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(' https://api.eventmassi.com/api/v2/admin/faqs/get-all-faqs')
      .then((res) => {
        console.log(res.data.data);
        setData(res.data.data);
      })
      .catch((e) => console.log(e));
  }, []);

  return (
    <div>
      <img
        className="w-35 mx-auto d-flex"
        src="https://www.beta.eventmassi.com/_next/static/media/faqs-bg-img.7468b385.png"
        alt=""
      />
      <h1 className="questions" style={{ fontSize: '30px' }}>
        Do You Have Any Questions About Event Massi?
      </h1>
      <h3 className="text-center">
        Learn More About Event Massi Below, Or Contact Us!
      </h3>
      <h1 className="fs-1 fw-bolder mt-5 mx-5 ">Frequently Asked Questions</h1>

      {/* 
      <div className="borderfaq"> */}
      {data.map((data, index) => (
        <div className="mx-5 mt-5" key={index}>
          <hr></hr>
          <h2>{`${data.title}`}</h2>

          <p>{`${data.description}`}</p>
        </div>
      ))}
      {/* </div> */}

      <div className="container mb-5 mt-5 feedback p-5">
        <h1 className="display-4 ">Give Us Your Feedback</h1>
        <h3 className="text-center mt-3 opacity-50">
          Do you have any questions or concerns?
        </h3>
        {/* <button
          
          className="mx-auto mt-5 d-flex mb-4 bg-warning border border-secondary text-light rounded-3 px-4"
          style={{ fontSize: '30px', fontFamily: 'Roboto, sans-serif;' }}
        >
          Contact us
        </button>{' '} */}

        <a href="/contact_us" className="contact ">
          Contact Us
        </a>
      </div>
    </div>
  );
};

export default Massi;
