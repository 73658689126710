import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import VendorService from '../services/VendorService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContent } from '../components/Toast';

class ContactVendorFormModal extends React.Component {
  state = {};

  submitContactVendor = async (e) => {
    e.preventDefault();
    try {
      var name = e.target.elements.name.value;
      var email = e.target.elements.email.value;
      var phone = e.target.elements.phone.value;
      var desiredDate = e.target.elements.date.value;
      var guestCount = e.target.elements.guest.value;
      var preferredContact = ['email', 'phone', 'text'];
      var message = e.target.elements.message.value;
      var user = this.props.vendorProfile.data.id;
      var params = {
        name,
        email,
        phone,
        desiredDate,
        guestCount,
        preferredContact,
        message,
        user,
      };
      var sendmsg = await VendorService.sendContactMessageToVendor(params);
      if (sendmsg.success) {
        this.props.closeModal();
        toast(() => ToastContent(sendmsg.message), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
      } else {
        toast(() => ToastContent(sendmsg.message), {
          toastId: 'errorToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } catch (err) {
      toast(() => ToastContent(err), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  render() {
    const { vendorProfile, closeBtn, showContactForm } = this.props;
    return (
      <>
        <Modal
          isOpen={showContactForm}
          className="sidebar-lg"
          contentClassName="p-0"
          modalClassName="modal-slide-in event-sidebar"
        >
          <ModalHeader className="mb-1" tag="div" close={closeBtn}>
            <h4>Contact this vendor</h4>
          </ModalHeader>
          <ModalBody className="flex-grow-1 pb-sm-0 pb-3">
            <form onSubmit={this.submitContactVendor}>
              <div
                id="booking-widget-anchor"
                className="boxed-widget booking-widget sign-in-form col-md-12"
              >
                <div className="row with-forms  margin-top-0">
                  <p className="row">
                    <label htmlFor="name">
                      Your name
                      <i className="im im-icon-Male"></i>
                      <input
                        type="text"
                        className="input-text2"
                        name="name"
                        id="name"
                        placeholder="Enter your name here"
                        required
                      />
                    </label>
                  </p>
                  <p className="row">
                    <label htmlFor="email">
                      Email
                      <i className="im im-icon-Email"></i>
                      <input
                        type="email"
                        className="input-text2"
                        name="email"
                        id="email"
                        placeholder="Enter your email here"
                        required
                      />
                    </label>
                  </p>
                  <p className="row">
                    <label htmlFor="phone">
                      Phone
                      <i className="im im-icon-Phone-2"></i>
                      <input
                        type="text"
                        className="input-text2"
                        name="phone"
                        id="phone"
                        placeholder="Enter your phone number here"
                      />
                    </label>
                  </p>
                  <p className="row">
                    <div className="col-md-6 padding-left-0 padding-right-0">
                      <label htmlFor="date">
                        Desired date
                        <i className="im im-icon-Calendar"></i>
                        <input
                          type="date"
                          className="input-text2"
                          name="date"
                          id="date"
                        />
                      </label>
                    </div>
                    <div className="col-md-6 padding-right-0">
                      <label htmlFor="guest">
                        Guest
                        <i className="im im-icon-User"></i>
                        <input
                          type="number"
                          className="input-text2"
                          name="guest"
                          id="guest"
                          defaultValue="1"
                        />
                      </label>
                    </div>
                  </p>
                  <p className="row">
                    <label>Preferred contact method</label>
                    <div className="radio margin-right-5">
                      <input id="radio-1" name="radio" type="radio" />
                      <label htmlFor="radio-1">
                        <span className="radio-label"></span> Email
                      </label>
                    </div>

                    <div className="radio margin-right-5">
                      <input id="radio-2" name="radio" type="radio" />
                      <label htmlFor="radio-2">
                        <span className="radio-label"></span> Phone
                      </label>
                    </div>
                    <div className="radio">
                      <input id="radio-3" name="radio" type="radio" />
                      <label htmlFor="radio-3">
                        <span className="radio-label"></span> Text
                      </label>
                    </div>
                  </p>
                  <p className="row">
                    <div className="form">
                      <label htmlFor="message">
                        Message
                        <textarea
                          className="WYSIWYG"
                          name="message"
                          cols="40"
                          rows="3"
                          id="message"
                          spellCheck="true"
                        ></textarea>
                      </label>
                    </div>
                  </p>
                </div>

                <button
                  type="submit"
                  className="button book-now fullwidth margin-top-5"
                >
                  Request To Book
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default ContactVendorFormModal;
