import React from 'react';
import { Link } from 'react-router-dom';
import { URLS, Local_routes } from '../util/constants';
import { Rings } from 'react-loader-spinner';
import './home.css';
class HomeVendorListSection extends React.Component {
  render() {
    const { vendors } = this.props;
    return (
      <div>
        <div className="homeTopPicksWrap">
          <div className="container">
            <div className="topPicksHeading">
              <img
                className="img "
                src="https://www.beta.eventmassi.com/_next/static/media/top-picks.62bdfdab.svg"
                alt="img"
              />
            </div>
            {vendors.length === 0 ? (
              <div
                className="col-lg-12"
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Rings
                  height="200"
                  width="200"
                  radius="9"
                  color="#edb300"
                  ariaLabel="three-dots-loading"
                />
              </div>
            ) : null}
            {vendors.success
              ? vendors.data.map((vendor) => {
                  return (
                    <div className="topPicksBoxWrap ">
                      <div
                        className="topPicksContentWrap "
                        key={`${vendor.id}`}
                      >
                        <div>
                          {/* <!-- Image -->  */}
                          <Link
                            to={
                              Local_routes.vendor_detail + '/' + btoa(vendor.id)
                            }
                          />
                        </div>
                        <div className="topPickPrdImg ">
                          <img
                            src={`${URLS.S3BucketURL}${vendor.pic_path}`}
                            alt=""
                            className=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = './images/default.jpeg';
                            }}
                          />
                        </div>

                        {/* {/* <!-- Content -->  */}
                        <div className="topPickPrdContent col-6">
                          <div className="cursor-pointers ">
                            <Link
                              to={
                                Local_routes.vendor_detail +
                                '/' +
                                btoa(vendor.id)
                              }
                            >
                              <h5 className="vendor ">{vendor.business}</h5>
                            </Link>
                            <div className="price-wrapper">
                              <div className="topPickLocationPriceWrap">
                                <div className="prdlocation">
                                  <img
                                    className="mx-1"
                                    src="https://www.beta.eventmassi.com/_next/static/media/pin.c824f63e.svg"
                                    alt=""
                                  />
                                  {vendor.states.length > 0 ? (
                                    <span>{vendor.states[0].name}</span>
                                  ) : null}

                                  <div className="rating-counter1 ">
                                    <span className="text-success">
                                      {vendor.costly === '1D'
                                        ? '$'
                                        : vendor.costly === '2D'
                                        ? '$$'
                                        : vendor.costly === '3D'
                                        ? '$$$'
                                        : null}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="topPickPrdContent">
                              <Link
                                to={
                                  Local_routes.vendor_detail +
                                  '/' +
                                  btoa(vendor.id)
                                }
                                className="highlighted-category bg-dark "
                              ></Link>
                            </div>
                            <div className="topPickBoxBtnsWrap">
                              <button className="contactBtn">Contact</button>

                              {/* {vendor.deals.length > 0 ? ( */}
                              <a href="listings-list-with-sidebar.html">
                                <button className="dealsBtn mx-2">Deals</button>
                              </a>
                              {/* ) : null} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              : null}{' '}
          </div>
        </div>
      </div>
    );
  }
}

export default HomeVendorListSection;
