import React from 'react';
import { Input, FormGroup, Button } from 'reactstrap';
import CommonService from '../services/CommonService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContent } from '../components/Toast';
import InstaFeed from '../pages/layouts/InstaFeed';
import './home.css';
class HomeFollowUsSection extends React.Component {
  subscribeNewLetter = async (e) => {
    e.preventDefault();
    toast.dismiss();
    var email = e.target.elements.email.value;
    if (email === '' || email === null) {
      toast(() => ToastContent('Please enter valid email.'), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
      return false;
    }
    try {
      var send = await CommonService.subscribeNewsLetter(email);
      e.target.elements.email = '';
      if (send?.success) {
        toast(() => ToastContent(send.message), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        });
        return true;
      } else {
        toast(() => ToastContent(send.message), {
          toastId: 'errorToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1500,
        });
      }
    } catch (err) {
      toast(() => ToastContent('You have not subscribed to Newsletter.'), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <section
          className="fullwidth padding-top-75 padding-bottom-70"
          style={{ backgroundColor: '#f9f9f9' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="headline">
                  <strong className="headline-with-separator">
                    Follow &amp; Join Us
                  </strong>
                  <span className="margin-top-25">
                    Follow us on multiple social media &amp; checkout our
                    instagram feeds what user says about us &amp; subscribe our
                    newsletters
                  </span>
                </h3>
              </div>
            </div>
            {/* */}
            <div className="col-lg-4">
              <div className="homeSecondSectionBoxWrap">
                <h4>Join our facebook group</h4>
                <div className="fbGrpWrap">
                  <img
                    src="https://www.beta.eventmassi.com/_next/static/media/facebook-group.451366b3.png"
                    alt=""
                  />
                  <h5>Follow @eventmassi</h5>
                  <ul>
                    <li>
                      <a
                        href="https://www.facebook.com/eventmassi"
                        rel="noreferrer"
                        target={'_blank'}
                      >
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/fb-icon.2329df88.svg"
                          alt="img"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/eventmassi"
                        rel="noreferrer"
                        target={'_blank'}
                      >
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/instagram-icon.80c6cd1f.svg"
                          alt="img"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.tiktok.com/eventmassi"
                        rel="noreferrer"
                        target={'_blank'}
                      >
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/tiktok-icon.85d21fee.svg"
                          alt="img"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.twitter.com/eventmassi"
                        rel="noreferrer"
                        target={'_blank'}
                      >
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/twitter-icon.394ab5a8.svg"
                          alt="img"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/*  */}

            <div className="col-lg-4">
              <div className="homeSecondSectionBoxWrap">
                <h4>Check us out on Instagram!</h4>
                {/* <i className="im im-icon-Instagram"></i> */}
                {/* <img
                  className="mx-auto d-flex mt-3"
                  style={{ width: '15%' }}
                  src="https://cdn-icons-png.flaticon.com/512/174/174855.png"
                  alt=""
                /> */}
                <InstaFeed />
              </div>
            </div>

            <div className="col-lg-4">
              <div className="homeSecondSectionBoxWrap subscribeBoxWrap">
                <h4>Subscribe for more updates from Event Massi</h4>
                {/* <i className="im im-icon-Mail-withAtSign"></i> */}
                <form
                  className="newsLetterForm"
                  onSubmit={this.subscribeNewLetter}
                >
                  <FormGroup className="margin-top-10 ">
                    <Input
                      type="email"
                      id="email"
                      className="customFormControl"
                      placeholder="Enter Email "
                    />
                  </FormGroup>
                  <button className="subscribeBtn ">Subscribe!</button>
                </form>
                {/* <form className="newsletterForm">
                  <input
                    type="email"
                    name=""
                    className="customFormControl"
                    placeholder="Enter Email"
                    id=""
                    value=""
                  /> */}

                {/* </form> */}
              </div>
            </div>

            {/* <div className="col-md-4 ">
              <div className="icon-box-2 howitWorksBox"> */}

            {/* <h3>Subscribe For More Updates From Event Massi</h3>
            <form onSubmit={this.subscribeNewLetter}>
              <FormGroup className="margin-top-15">
                <Input
                  type="email"
                  id="email"
                  placeholder="Enter Email Here!"
                />
              </FormGroup> */}
            {/* <div className="data-list-sidebar-footer px-2 d-flex justify-content-start align-items-center mt-1">
                    <Button
                      className=" button bg-dark text-light "
                      type="submit"
                    >
                      Subscribe!
                    </Button>
                  </div> */}
            {/* </form> */}
            {/* </div>
            </div> */}
          </div>
        </section>
      </div>
    );
  }
}

export default HomeFollowUsSection;
