import React from 'react';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContent } from '../components/Toast';
class VendorMyInformation extends React.Component {
  selectedCategories = [];
  selectedStates = [];
  componentDidMount() {}

  handleSubmit = (e) => {
    e.preventDefault();
    toast.dismiss();
    var firstName = e.target.elements.firstname.value;
    var lastName = e.target.elements.lastname.value;
    var email = e.target.elements.email.value;
    var phone = e.target.elements.phone.value;
    var business = e.target.elements.business.value;
    var categories = [];
    this.selectedCategories.map((category) => {
      return categories.push(category.value);
    });
    if (categories.length === 0) {
      toast(() => ToastContent('Atleast one category required.'), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return false;
    }
    var address = e.target.elements.address.value;
    var states = [];
    this.selectedStates.map((state) => {
      return states.push(state.value);
    });
    if (states.length === 0) {
      toast(() => ToastContent('Atleast one state required.'), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return false;
    }
    var willingToTravel = e.target.elements.willingtotravel.checked;
    var params = {
      firstName,
      lastName,
      email,
      phone,
      business,
      categories,
      address,
      states,
      willingToTravel,
    };
    this.props.updateVendorInfo(params);
  };

  handleCategoryChange = (handleCategoryChange) => {
    this.selectedCategories = handleCategoryChange;
  };

  handleStateChange = (handleStateChange) => {
    this.selectedStates = handleStateChange;
  };

  render() {
    const { vendorProfile, allCategories, allStates } = this.props;
    const categoriesOptions = [];
    const stateOptions = [];
    this.selectedCategories = [];
    this.selectedStates = [];

    if (allCategories.length > 0) {
      allCategories.map((category) => {
        return categoriesOptions.push({
          value: category.id,
          label: category.title,
        });
      });
    }

    if (allStates.length > 0) {
      allStates.map((state) => {
        return stateOptions.push({ value: state.id, label: state.name });
      });
    }

    vendorProfile.categories.map((category) => {
      return this.selectedCategories.push({
        value: category.id,
        label: category.title,
      });
    });

    vendorProfile.states.map((state) => {
      return this.selectedStates.push({
        value: state.id,
        label: state.name,
      });
    });

    return (
      <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form
            onSubmit={this.handleSubmit}
            className="generalFormWrap myInformationForm"
          >
            <h3 className="editFormHeading">My Information</h3>
            <div className="customFormGrp">
              <label>First Name</label>
              <div className="formControlWrap">
                <input
                  type="text"
                  name=""
                  className="customFormControl"
                  // placeholder="Tom"
                  id="firstname"
                  defaultValue={vendorProfile.firstName}
                  required
                  autoCapitalize="on"
                  autoComplete={false}
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>Last Name</label>
              <div className="formControlWrap">
                <input
                  type="text"
                  name=""
                  className="customFormControl"
                  // placeholder="Perrin"
                  id="lastname"
                  defaultValue={vendorProfile.lastName}
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Email Address<span className="redDot">*</span>
              </label>
              <div className="formControlWrap">
                <input
                  type="email"
                  name=""
                  className="customFormControl"
                  // placeholder="tom@example.com"
                  defaultValue={vendorProfile.email}
                  id="email"
                  disabled={true}
                  required
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Phone Number<span className="redDot">*</span>
              </label>
              <div className="formControlWrap">
                <input
                  name=""
                  className="customFormControl"
                  // placeholder="(123) 123-456"
                  type="phone"
                  id="phone"
                  defaultValue={vendorProfile.phone}
                  required
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Vendor Name<span className="redDot">*</span>
              </label>
              <div className="formControlWrap">
                <input
                  name=""
                  className="customFormControl"
                  type="text"
                  defaultValue={vendorProfile.business}
                  id="business"
                  required
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Vendor Category<span className="redDot">*</span>
              </label>
              <div className="formControlWrap">
                <Select
                  id="categories"
                  // placeholder="Please select category"
                  options={categoriesOptions}
                  isMulti={true}
                  name="select_category"
                  noOptionsMessage="No category listed"
                  isDisabled={categoriesOptions.length === 0 ? true : false}
                  onChange={this.handleCategoryChange}
                  className="vendorprofile"
                  defaultValue={this.selectedCategories}
                  required
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>Address &amp; City</label>
              <div className="formControlWrap">
                <input
                  type="text"
                  name=""
                  className="customFormControl"
                  // placeholder="Address name here"
                  defaultValue={vendorProfile.address}
                  id="address"
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                State<span className="redDot">*</span>
              </label>
              <div className="formControlWrap">
                <Select
                  id="states"
                  // placeholder="Please select state"
                  options={stateOptions}
                  isMulti={true}
                  name="select_state"
                  noOptionsMessage="No state listed"
                  isDisabled={stateOptions.length === 0 ? true : false}
                  onChange={this.handleStateChange}
                  defaultValue={this.selectedStates}
                  className="vendorprofile"
                  required
                />
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Willing To Travel<span className="redDot">*</span>
              </label>
              <div className="formControlWrap">
                <label className="switch">
                  <input
                    type="checkbox"
                    name="willingtotravel"
                    id="willingtotravel"
                    defaultChecked={vendorProfile.willingToTravel === true}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
            <div className="customFormGrp btnFormGrp">
              <button className="editProfileSubmitBtn col-lg-12">
                Update Info
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default VendorMyInformation;
