import React from "react";
import HomeBannerSlider from "../../components/HomeBannerSlider";
import HomeFollowUsSection from "../../components/HomeFollowUsSection";
import HomeVendorListSection from "../../components/HomeVendorListSection";
import VendorService from "../../services/VendorService";
import HeaderDocumentDetail from "../../components/HeaderDocumentDetail";
class Home extends React.Component {
  state = {
    massiTopPickVendors: [],
  };

  async componentDidMount() {
    let vendors = await VendorService.getMassiTopPickVendores();
    this.setState({ massiTopPickVendors: vendors });
  }

  //render method
  render() {
    return (
      <>
        <HeaderDocumentDetail title="Event Massi | Your next event planner" />;
        <HomeBannerSlider />
        <HomeFollowUsSection />
        <HomeVendorListSection vendors={this.state.massiTopPickVendors} />
      </>
    );
  }
}

export default Home;
