import React, { useState, useEffect } from 'react';
import './InstaFeed.css';
import { Link } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import { Local_routes } from '../../util/constants';
function SearchBar({ placeholder }) {
  const [APIData, setAPIData] = useState([]);
  const [keyData, setkeyData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [stateFilterData, setStateFilterData] = useState([]);
  const [stateEntered, setStateEntered] = useState('');
  const [catFilteredData, setcatFilteredData] = useState([]);
  const [catEntered, setcatEntered] = useState('');
  const [keyfilteredData, setKeyFilteredData] = useState([]);
  const [keyEntered, setKeyEntered] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [wordEntered, setWordEntered] = useState('');
  useEffect(() => {
    axios
      .get(`https://api.eventmassi.com/api/v2/get-all-users`)
      .then((response) => {
        setAPIData(response.data.data);
        console.log(response.data.data);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`https://api.eventmassi.com/api/v2/get-all-categories`)
      .then((response) => {
        setCatData(response.data.data);
        console.log(response.data.data);
      });
  }, []);
  useEffect(() => {
    axios
      .get(`https://api.eventmassi.com/api/v2/get-all-keywords`)
      .then((response) => {
        setkeyData(response.data.data);
        console.log(response.data.data);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://api.eventmassi.com/api/v2/get-all-states`)
      .then((response) => {
        setStateData(response.data.data);
        console.log(response.data.data);
      });
  }, []);
  const handleFilter = (event) => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = APIData.filter((value) => {
      return Object.values(value)
        .join('')
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });
    const catWord = event.target.value;
    setcatEntered(catWord);
    const catFilter = catData.filter((value) => {
      return Object.values(value)
        .join('')
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });
    const keyWord = event.target.value;
    setKeyEntered(keyWord);
    const keyFilter = keyData.filter((value) => {
      return Object.values(value)
        .join('')
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });

    const stateWord = event.target.value;
    setStateEntered(stateWord);
    const stateFilter = stateData.filter((value) => {
      return Object.values(value)
        .join('')
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });

    if (searchWord === '') {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
    }
    if (catWord === '') {
      setcatFilteredData([]);
    } else {
      setcatFilteredData(catFilter);
    }
    if (keyWord === '') {
      setKeyFilteredData([]);
    } else {
      setKeyFilteredData(keyFilter);
    }

    if (stateWord === '') {
      setStateFilterData([]);
    } else {
      setStateFilterData(stateFilter);
    }
  };

  const clearInput = () => {
    setFilteredData([]);
    setWordEntered('');
    setcatFilteredData([]);
    setcatEntered('');
    setKeyFilteredData([]);
    setKeyEntered('');
    setStateFilterData([]);
    setStateEntered('');
  };

  return (
    <div className="search  ">
      <div className="searchInputs">
        <div className="searchIcon">
          {filteredData.length === 0 ? (
            <SearchIcon />
          ) : (
            <CloseIcon id="clearBtn" onClick={clearInput} />
          )}
        </div>
        <input
          type="text"
          placeholder="Search"
          className="text-warning"
          value={(wordEntered, catEntered, keyEntered, stateEntered)}
          onChange={handleFilter}
        />
      </div>
      {filteredData.length != 0 && (
        <div className="dataResult">
          <h3 className="mx-3">Vendors</h3>
          {filteredData.slice(0, 15).map((value, key) => {
            return (
              <a
                target="_self"
                href={Local_routes.vendor_detail + '/' + btoa(value.id)}
                className="vendorInsideContentDetails dataItem"
              >
                <p>{value.business} </p>
                {/* <a className="dataItem" href={value.link} target="_blank"></a> */}
              </a>
            );
          })}
        </div>
      )}

      {catFilteredData.length != 0 && (
        <div className="categoryResult">
          <h3 className="mx-3">Category</h3>
          {catFilteredData.slice(0, 15).map((value, key) => {
            return (
              <a className="dataItem" href={value.link} target="_blank">
                <p>{value.title} </p>
              </a>
            );
          })}
        </div>
      )}
      {keyfilteredData.length != 0 && (
        <div className="keywordResult">
          <h3 className="mx-3">KeyWords</h3>
          {keyfilteredData.slice(0, 15).map((value, key) => {
            return (
              <a className="dataItem" href={value.link} target="_blank">
                <p>{value.name} </p>
              </a>
            );
          })}
        </div>
      )}
      {stateFilterData.length != 0 && (
        <div className="stateResult">
          <h3 className="mx-3">States</h3>
          {stateFilterData.slice(0, 15).map((value, key) => {
            return (
              <a className="dataItem" href={value.link} target="_blank">
                <p>{value.name} </p>
              </a>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default SearchBar;
