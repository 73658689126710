import React from 'react';
import './About.css';
const About = () => {
  return (
    <div>
      <img
        className="d-flex mx-auto"
        src="https://www.beta.eventmassi.com/_next/static/media/about-page-img.75b36902.png"
        alt=""
      />
      <h1 className="Massi">About Event Massi</h1>
      <div className="container ">
        <p className="text-center">
          Our Goal Is To Create A Community Of Local Vendors Right At Your
          Fingertips!
        </p>
      </div>
      <div className="container-fluid p-5  bg-light mt-5">
        <p className="about col-sm-9 col-lg-12">
          As millennials, we have attended back to back family and friend’s
          wedding events, especially south asian weddings. We noticed a gap
          between vendors and clients in their community. Those who are not up
          to date with trending vendors in their area wouldn’t know where to
          look for their events, whether it’s a home event, or a wedding
        </p>
        <p className="about col-lg-12">
          During one particular friend’s wedding in 2019, we came up with the
          concept of Event Massi- a one stop vendor shop for all your event
          needs! It was apparent that all these events had one thing in common,
          an all knowing Aunt (Massi- translation in Urdu means Aunt). So, why
          not have an Event Massi who could assist the community and make your
          life easier? That was how the idea of Event Massi was created. The
          approach for our directory is creating a supportive network and
          family, where the Massi is the liaison between clients and vendors
          from all categories!
        </p>
        <p className="about col-sm-9 col-lg-12">
          Event Massi was established in the summer of 2020, when two childhood
          friends from Long Island, NY were determined to help bridge the gap in
          the community. As we attended multiple family and friends’ weddings
          over the years, we noticed the frustrations faced by those planning
          their events. As well as small businesses not getting exposure beyond
          social media. Seeing issues firsthand arising with in regards to all
          events, were a little shocking- who knew how much went into planning
          events!
        </p>
        <p className="mb-5 col-sm-9 col-lg-12 about">
          Since the launch of Event Massi, we have been blown away by the
          support from both vendors and clients in the tri-state area. Whether
          it was vendors collaborating for larger events, or the average
          consumer looking for a service for their backyard event, Event Massi
          has already created a proven network. Our goal is giving exposure to
          all types of businesses that help make events successful and
          memorable. Event Massi is a fabric in the community and we are proud
          to have a unique directory consisting of vendors who want to support
          each other, and grow with Massi Nation!
        </p>
      </div>
    </div>
  );
};

export default About;
