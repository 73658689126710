//local routes of the website
export const Local_routes = {
  home: '/',
  explore: '/explore',
  massi: '/massi',
  blogs: '/blogs',
  about_us: '/about_us',
  policy: '/policy',
  vendors: '/browse-vendors',
  contact_us: '/contact_us',
  vendor_detail: '/browse-vendor',
  dashboard: '/dashboard',
  payment: '/payment',
};

//api routes
const BaseUrl = 'https://api.eventmassi.com/';
// const BaseUrl = 'http://3.223.225.133:5000/';
const ApiPoint = BaseUrl + 'api/v2/';

const S3BucketURL = 'https://massi-bucket.s3.amazonaws.com/';
export const ApiRoute = {
  massiTopPicks: ApiPoint + 'top-vendors',
  browseVendors: ApiPoint + 'browse-vendors',
  vendorProfile: ApiPoint + 'vendor-profile',
  login: ApiPoint + 'auth/login',
  register: ApiPoint + 'auth/register',
  getCategories: ApiPoint + 'get-all-categories',
  getStates: ApiPoint + 'get-all-states',
  getVendorContactMessages: ApiPoint + 'vendor-contact',
  getLoginVendorProfile: ApiPoint + 'vendor-profile-me',
  getSubscriptionList: ApiPoint + 'get-subscriptions',
  getAllKeywords: ApiPoint + 'get-all-keywords',
  updateVendorProfile: ApiPoint + 'update-vendor-profile',
  uploadVendorProfileImage: ApiPoint + 'uploads/vendor-profile-image',
  uploadVendorAssets: ApiPoint + 'uploads/vendor-media',
  exploreVendor: ApiPoint + 'explore',
  uploadVendorVideos: ApiPoint + 'uploads/video/vendor-media',
  createNewDeal: ApiPoint + 'create-new-deal',
  deleteDeal: ApiPoint + 'delete-deal/',
  subscribeNewsletter: ApiPoint + 'subscribe-newsletter',
  contactUs: ApiPoint + 'contact-us',
};

export const URLS = {
  BaseUrl,
  ApiPoint,
  S3BucketURL,
};

export const CONSTANTS = {
  REQUEST_HEADERS: {
    Accept: 'application/json , text/plain, */*',
    'Content-Type': 'application/json',
  },
};
