import React from "react";
import { Rings } from "react-loader-spinner";
import UploadVendorLogo from "./UploadVendorLogo";
import VendorMyInformation from "./VendorMyInformation";
import VendorCompanyAndServiceInfo from "./VendorCompanyAndServiceInfo";
import VendorSocialMediaProfile from "./VendorSocialMediaProfile";
class LoginVendorProfile extends React.Component {
  render() {
    const { vendorProfile, allCategories, allStates, allKeywords } = this.props;
    return (
      <>
        {vendorProfile == null ? (
          <div
            className="col-lg-12"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Rings
              height="200"
              width="200"
              radius="9"
              color="#edb300"
              ariaLabel="three-dots-loading"
            />
          </div>
        ) : (
          <>
            <UploadVendorLogo
              vendorProfile={vendorProfile}
              updateVendorInfo={(params) => this.props.updateVendorInfo(params)}
            />
            <VendorMyInformation
              vendorProfile={vendorProfile}
              allCategories={allCategories}
              allStates={allStates}
              updateVendorInfo={(params) => this.props.updateVendorInfo(params)}
            />
            <VendorCompanyAndServiceInfo
              vendorProfile={vendorProfile}
              allKeywords={allKeywords}
              updateVendorInfo={(params) => this.props.updateVendorInfo(params)}
            />
            <VendorSocialMediaProfile
              vendorProfile={vendorProfile}
              updateVendorInfo={(params) => this.props.updateVendorInfo(params)}
            />
          </>
        )}
      </>
    );
  }
}

export default LoginVendorProfile;
