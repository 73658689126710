import React from 'react';
import VendorImageAssetUploader from './VendorImageAssetUploader';
import VendorVideoAssetUploader from './VendorVideoAssetUploader';
import VendorUploadedAssets from './VendorUploadedAssets';
import VendorService from '../services/VendorService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContent } from '../components/Toast';

class VendorDashboardGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  uploadVendorAssetVideo = async (video) => {
    try {
      var uploadImage = await VendorService.uploadVendorVideoAssets(
        video.target.files[0]
      );
      if (uploadImage?.imagePath) {
        var newvideo = { url: uploadImage.imagePath, type: 'video' };
        var updateAssets = this.props.galleryData;
        updateAssets.push(newvideo);
        var params = { assets: updateAssets };
        var updateUserProfile = await VendorService.updateVendorInfo(params);
        if (updateUserProfile.success) {
          toast(() => ToastContent('Video uploaded successfully.'), {
            toastId: 'successToast',
            hideProgressBar: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
          });
          this.props.updateUserProfile(updateUserProfile.data);
        }
      }
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
    }
  };

  uploadVendorAssetImage = async (image) => {
    try {
      var uploadImage = await VendorService.uploadVendorAssets(
        image.target.files[0]
      );
      if (uploadImage?.imagePath) {
        var newimage = { url: uploadImage?.imagePath, type: 'image' };
        var updateAssets = this.props.galleryData;
        updateAssets.push(newimage);
        var params = { assets: updateAssets };
        var updateUserProfile = await VendorService.updateVendorInfo(params);
        if (updateUserProfile.success) {
          toast(() => ToastContent('Image uploaded successfully.'), {
            toastId: 'successToast',
            hideProgressBar: false,
            type: toast.TYPE.SUCCESS,
            autoClose: 1500,
          });
          this.props.updateUserProfile(updateUserProfile.data);
        }
      }
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
    }
  };

  deleteVendorAsset = async (asset) => {
    try {
      const newAssetsList = await this.props.galleryData.filter(
        (item) => item._id !== asset._id
      );
      var params = { assets: newAssetsList };
      var updateUserProfile = await VendorService.updateVendorInfo(params);
      if (updateUserProfile.success) {
        toast(() => ToastContent('Successfully Deleted.'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        });
        this.props.updateUserProfile(updateUserProfile.data);
      }
    } catch (err) {
      toast(() => ToastContent(err.message), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
    }
  };

  render() {
    return (
      <>
        <div class="tabTopBar ">
          <p>
            <strong>Subscribe!</strong> to preferred membership &amp; enchance
            your business. To subscribe <a href="#_">Click Here</a>
          </p>
        </div>
        <div className="container margin-top-10 margin-bottom-50">
          <div className="row">
            <div className="col-lg-12">
              <div className="padding-top-10 padding-bottom-10">
                <div className="margin-bottom-15" style={{ fontSize: '20px' }}>
                  <strong>
                    Upload photos and videos to be viewed on your vendor profile
                  </strong>
                </div>
                <div style={{ fontSize: '21px' }}>
                  Introduce yourself to the community to show what makes you
                  unique!
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="col-md-6 col-sm-12">
                <VendorImageAssetUploader
                  uploadVendorAssetImage={(image) =>
                    this.uploadVendorAssetImage(image)
                  }
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <VendorVideoAssetUploader
                  uploadVendorAssetVideo={(image) =>
                    this.uploadVendorAssetVideo(image)
                  }
                />
              </div>
            </div>
          </div>
          <div className="row margin-top-50">
            <div className="col-md-12">
              <VendorUploadedAssets
                assets={this.props.galleryData}
                deleteVendorAsset={(asset) => this.deleteVendorAsset(asset)}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default VendorDashboardGallery;
