import React from 'react';
import './vendor.css';
class VendorSocialMediaProfile extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();
    var socials = {
      website: this.props.vendorProfile?.socials?.website,
      facebook: e.target.elements.facebook.value,
      instagram: e.target.elements.instagram.value,
      pinterest: e.target.elements.pinterest.value,
      youtube: e.target.elements.youtube.value,
      tiktok: e.target.elements.tiktok.value,
      twitter: e.target.elements.twitter.value,
    };
    var params = {
      socials,
    };
    this.props.updateVendorInfo(params);
  };
  render() {
    const { vendorProfile } = this.props;
    return (
      <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form
            onSubmit={this.handleSubmit}
            className="generalFormWrap myInformationForm editMyInfo"
          >
            <h3 className="editFormHeading">My Social media profiles</h3>
            <div className="customFormGrp">
              <label>
                Facebook Username{' '}
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/fb-icon.2329df88.svg"
                  alt=""
                />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="facebook"
                  defaultValue={vendorProfile?.socials?.facebook}
                />
                <span className="fieldNote d-none">
                  (Note: Must use http:// or https:// before link)
                </span>
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Instagram Username{' '}
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/instagram-icon.80c6cd1f.svg"
                  alt=""
                />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="instagram"
                  defaultValue={vendorProfile?.socials?.instagram}
                />
                <span className="fieldNote d-none">
                  (Note: Must use http:// or https:// before link)
                </span>
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Pinterest Username{' '}
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/pinterest-icon.8a4c7cef.svg"
                  alt=""
                />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="pinterest"
                  defaultValue={vendorProfile?.socials?.pinterest}
                />
                <span className="fieldNote d-none">
                  (Note: Must use http:// or https:// before link)
                </span>
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Youtube Username{' '}
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/youtube-icon.63de6ca3.svg"
                  alt=""
                />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="youtube"
                  defaultValue={vendorProfile?.socials?.youtube}
                />
                <span className="fieldNote d-none">
                  (Note: Must use http:// or https:// before link)
                </span>
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Tiktok Username{' '}
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/tiktok-icon.85d21fee.svg"
                  alt=""
                />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="tiktok"
                  defaultValue={vendorProfile?.socials?.tiktok}
                />
                <span className="fieldNote d-none">
                  (Note: Must use http:// or https:// before link)
                </span>
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Twitter Username{' '}
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/twitter-icon.394ab5a8.svg"
                  alt=""
                />
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  id="twitter"
                  defaultValue={vendorProfile?.socials?.twitter}
                />
                <span className="fieldNote d-none">
                  (Note: Must use http:// or https:// before link)
                </span>
              </div>
            </div>
            <div className="customFormGrp btnFormGrp">
              <button className="editProfileSubmitBtn">
                Update Social Media
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default VendorSocialMediaProfile;
