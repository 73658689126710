/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Rings } from 'react-loader-spinner';
import { ToastContent } from '../components/Toast';
import AuthService from '../services/AuthService';
import './home.css';

class LoginModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signinTab: true,
      signupTab: false,
    };
  }

  submitLoginForm = async (event) => {
    toast.dismiss();
    event.preventDefault();
    var email = event.target.elements.email.value;
    var password = event.target.elements.password.value;
    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (validateEmail && password) {
      toast(() => ToastContent('Please wait....'), {
        toastId: 'infoToast',
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
      let loginData = await AuthService.login(email, password);
      toast.dismiss();
      if (loginData.success) {
        toast(() => ToastContent('Successfully login'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        console.log('TOKEN', loginData.token);
        await localStorage.setItem('userToken', btoa(loginData.token));
        await localStorage.setItem(
          'userData',
          btoa(JSON.stringify(loginData.user))
        );
        this.props.loginModalClose(1);
      } else {
        toast(() => ToastContent(loginData.error), {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } else {
      toast(() => ToastContent('Please enter valid credentials'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
    return;
  };

  submitRegisterForm = async (event) => {
    toast.dismiss();
    event.preventDefault();
    var email = event.target.elements.registeremail.value;
    var password = event.target.elements.registerpassword.value;
    var repeatpassword = event.target.elements.repeatpassword.value;
    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (!validateEmail) {
      toast(() => ToastContent('Please enter valid email address'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }

    if (password !== repeatpassword) {
      toast(() => ToastContent('Password & repeat password not matched'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }

    if (validateEmail && password === repeatpassword) {
      toast(() => ToastContent('Please wait....'), {
        toastId: 'infoToast',
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
      let loginData = await AuthService.register(email, password);
      toast.dismiss();
      if (loginData.success) {
        toast(() => ToastContent('Successfully register'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        await localStorage.setItem('userToken', btoa(loginData.token));
        await localStorage.setItem(
          'userData',
          btoa(JSON.stringify(loginData.user))
        );
        this.props.loginModalClose(1);
      } else {
        toast(() => ToastContent(loginData.message), {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    }
  };

  componentDidMount() {}
  render() {
    return (
      <>
        <ToastContainer />
        <Modal
          isOpen={this.props.signinupModal}
          id="sign-in-dialog"
          onClose={() => {
            this.props.loginModalClose();
            this.setState({ signinTab: true, signupTab: false });
          }}
        >
          {/* <div className="modal-header  "> */}

          {/* </div> */}
          <ModalBody style={{ backgroundColor: '#fff' }}>
            {/* <button
              type="button"
              className="close  text-light  "
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.props.loginModalClose();
                this.setState({ signinTab: true, signupTab: false });
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
            {/* <div className="tabs-container alt"> */}
            {this.state.signinTab ? (
              <div className="tab-content">
                <h1 className="text-center  vendorName">
                  Login To Your Vendor Account{' '}
                </h1>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.props.loginModalClose();
                    this.setState({ signinTab: true, signupTab: false });
                  }}
                >
                  <img
                    src="https://www.beta.eventmassi.com/_next/static/media/close-icon.6ef7d21d.svg"
                    alt=""
                  />
                </button>
                <form className="login" onSubmit={this.submitLoginForm}>
                  <p className="form-row form-row-wide">
                    {/* <label htmlFor="email"> */}
                    {/* Username: */}
                    {/* <i className="im im-icon-Male"></i> */}
                    <input
                      type="text"
                      className="input-text mt-4"
                      name="email"
                      id="email"
                      defaultValue={''}
                      placeholder="Enter Email"
                      autoComplete={false}
                    />
                    {/* </label> */}
                  </p>

                  <p className="form-row form-row-wide">
                    {/* <label htmlFor="password">
                          Password: */}
                    {/* <i className="im im-icon-Lock-2"></i> */}
                    <input
                      className="input-text"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Enter Password"
                      defaultValue={''}
                      autoComplete={false}
                    />
                    {/* </label> */}
                    {/* <span className="  "> */}
                    <h4
                      className="lost_password text-center mt-5 text-dark "
                      href="#"
                    >
                      Forgot Password?
                    </h4>
                    {/* </span> */}
                  </p>
                  <div className="customFormGroup">
                    <button
                      className="signUpBtn mt-5 d-flex mx-auto"
                      type="submit"
                      name="Register"
                      value="Login"
                    >
                      Login
                    </button>
                  </div>
                </form>
                <p className="formShortInfo mx-5">
                  By logging in, you agree with the Terms of Service and Privacy
                  Policy
                </p>
                <div className="signUpWithBox mt-4">
                  <span>Or Log In With</span>
                </div>
                <div className="socialLoginBoxes mt-5">
                  <a className="appleLogin">
                    <img
                      src="https://www.beta.eventmassi.com/_next/static/media/apple-icon.18d9951b.svg"
                      alt=""
                    />
                    <span>Apple ID</span>
                  </a>
                  <button>
                    <img
                      src="https://www.beta.eventmassi.com/_next/static/media/g-plus-icon.c93f23d6.svg"
                      alt=""
                    />
                    <span>Google</span>
                  </button>
                  <button className="facebookLogin">
                    <img
                      src="https://www.beta.eventmassi.com/_next/static/media/fb-fav-icon.0e844166.svg"
                      alt=""
                    />
                    <span>Facebook</span>
                  </button>
                </div>
                <p className="formShortInfo mx-5 mt-5">
                  We dont post anything to your social media, they are just used
                  to create your account.
                </p>
              </div>
            ) : (
              {
                /* <div
                  className="tab-content"
                  onSubmit={this.submitRegisterForm}
                ></div> */
              }
            )}
            {/* </div> */}
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default LoginModal;
