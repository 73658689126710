import React from 'react';
import { URLS } from '../util/constants';
import Select from 'react-select';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContent } from '../components/Toast';
import './vendor.css';
class VendorCompanyAndServiceInfo extends React.Component {
  selectedKeywords = [];
  costly = '';
  handleSubmit = (e) => {
    e.preventDefault();
    var description = e.target.elements.notes.value;
    var socials = {
      website: e.target.elements.website.value,
      facebook: this.props.vendorProfile?.socials?.facebook,
      instagram: this.props.vendorProfile?.socials?.instagram,
      pintrest: this.props.vendorProfile?.socials?.pintrest,
      youtube: this.props.vendorProfile?.socials?.youtube,
      tiktok: this.props.vendorProfile?.socials?.tiktok,
      twitter: this.props.vendorProfile?.socials?.twitter,
    };
    if (socials.website === '' || socials.website === null) {
      toast(() => ToastContent('Website is required.'), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }
    var keywords = [];
    this.selectedKeywords.map((keyword) => {
      return keywords.push(keyword.value);
    });
    var costly = this.costly;
    var params = {
      description,
      socials,
      keywords,
      costly,
    };
    this.props.updateVendorInfo(params);
  };

  handleKeywordChange = (handleKeywordChange) => {
    this.selectedKeywords = handleKeywordChange;
  };

  render() {
    const { vendorProfile, allKeywords } = this.props;
    const keywordsOptions = [];
    this.costly = vendorProfile.costly;
    this.selectedKeywords = [];

    if (allKeywords.length > 0) {
      allKeywords.map((keyword) => {
        return keywordsOptions.push({
          value: keyword.id,
          label: keyword.name,
        });
      });
    }

    vendorProfile.keywords.map((keyword) => {
      return allKeywords.map((item) => {
        if (keyword === item.id) {
          return this.selectedKeywords.push({
            value: keyword,
            label: item.name,
          });
        }
      });
    });

    return (
      <div className="editProfileContentWrap mt-5">
        <div className="container">
          <form
            onSubmit={this.handleSubmit}
            className="generalFormWrap myInformationForm"
          >
            <h3 className="editFormHeading">
              Tell us about your company &amp; services
            </h3>
            <div className="customFormGrp">
              <label>Description</label>
              <div className="formControlWrap">
                <textarea
                  className="customFormControl"
                  name="notes"
                  id="notes"
                  cols="30"
                  rows="10"
                  defaultValue={vendorProfile.description}
                ></textarea>
                <span className="fieldNote">0 Words Written</span>
              </div>
            </div>
            <div className="customFormGrp">
              <label>Keywords </label>
              <Select
                placeholder="Please select keywords"
                options={keywordsOptions}
                isMulti={true}
                name="select_keywords"
                noOptionsMessage="No keyword listed"
                isDisabled={keywordsOptions.length === 0 ? true : false}
                className="vendorprofile w-75"
                defaultValue={this.selectedKeywords}
                onChange={this.handleKeywordChange}
              />
              {/* <label>Keywords</label>
              <div className="formControlWrap">
                <div className="ant-select ant-select-multiple ant-select-show-search">
                  <div className="ant-select-selector">
                    <div className="ant-select-selection-overflow">
                      <div className="ant-select-selection-overflow-item ant-select-selection-overflow-item-suffix">
                        <div className="ant-select-selection-search">
                          <input
                            type="search"
                            autocomplete="off"
                            className="ant-select-selection-search-input"
                            role="combobox"
                            aria-haspopup="listbox"
                            aria-owns="rc_select_2_list"
                            aria-autocomplete="list"
                            aria-controls="rc_select_2_list"
                            aria-activedescendant="rc_select_2_list_0"
                            readonly=""
                            unselectable="on"
                            value=""
                            id="rc_select_2"
                          />
                          <span
                            className="ant-select-selection-search-mirror"
                            aria-hidden="true"
                          >
                            &nbsp;
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="ant-select-selection-placeholder">
                      Please select
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="customFormGrp">
              <label>Vendor Price</label>
              <div className="radio-wrapper">
                <input
                  type="radio"
                  name="costly"
                  id="option-1"
                  defaultValue={'1D'}
                  defaultChecked={vendorProfile.costly === '1D'}
                  onChange={() => (this.costly = '1D')}
                />
                <input
                  type="radio"
                  name="costly"
                  id="option-2"
                  defaultValue={'2D'}
                  defaultChecked={vendorProfile.costly === '2D'}
                  onChange={() => (this.costly = '2D')}
                />
                <input
                  type="radio"
                  name="costly"
                  id="option-3"
                  defaultValue={'3D'}
                  defaultChecked={vendorProfile.costly === '3D'}
                  onChange={() => (this.costly = '3D')}
                />
                <label htmlFor="option-1" className="option option-1">
                  <span>$</span>
                </label>
                <label htmlFor="option-2" className="option option-2">
                  <span>$$</span>
                </label>
                <label htmlFor="option-3" className="option option-3">
                  <span>$$$</span>
                </label>
              </div>
            </div>
            <div className="customFormGrp">
              <label>
                Vendor Website<span className="redDot">*</span>
              </label>
              <div className="formControlWrap">
                <input
                  type="text"
                  className="customFormControl"
                  placeholder="Enter Email"
                  defaultValue={vendorProfile?.socials?.website}
                  id="website"
                />
                <span className="fieldNote">
                  (Note: Must use http:// or https:// before link){' '}
                </span>
              </div>
            </div>
            <div className="customFormGrp btnFormGrp">
              <button className="col-lg-12 editProfileSubmitBtn">
                Update About Us
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default VendorCompanyAndServiceInfo;
