import React from 'react';

import { Link } from 'react-router-dom';
import './layout.css';
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <footer className="customFooter">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <img
                  className="footerLogo"
                  src="https://www.beta.eventmassi.com/_next/static/media/footer-logo.4bfc0d32.svg"
                  alt=""
                />
                <p className="footer1stPara">
                  Your one stop shop to find all of your favorite event vendors
                  and venues in your area! Want to get listed?
                </p>
                <button className="joinUsBtn">Join Us</button>
              </div>
              <div className="col-md-4">
                <div className="footerLinks">
                  <h6>Useful Links</h6>
                  <ul>
                    <li>
                      <a href="/about_us">About us</a>
                    </li>
                    <li>
                      <a href="/contact_us">Contact us</a>
                    </li>
                    <li>
                      <a href="/massi">Why Massi</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footerFollowLinks">
                  <h6>Follow Us</h6>
                  <ul>
                    <li>
                      {' '}
                      <a
                        href="https://twitter.com/eventmassi?lang=en"
                        rel="noreferrer"
                        target={'_blank'}
                      >
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/twitter-icon.394ab5a8.svg"
                          alt=""
                        />{' '}
                      </a>
                    </li>
                    <li>
                      {' '}
                      <a
                        href="https://www.facebook.com/eventmassi/"
                        rel="noreferrer"
                        target={'_blank'}
                      >
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/fb-icon.2329df88.svg"
                          alt=""
                        />{' '}
                      </a>
                    </li>
                    <li>
                      {' '}
                      <a
                        href="https://www.instagram.com/eventmassi/?hl=en"
                        rel="noreferrer"
                        target={'_blank'}
                      >
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/instagram-icon.80c6cd1f.svg"
                          alt=""
                        />{' '}
                      </a>
                    </li>

                    <li>
                      {' '}
                      <a
                        href="https://www.tiktok.com/@eventmassi"
                        rel="noreferrer"
                        target={'_blank'}
                      >
                        <img
                          src="https://www.beta.eventmassi.com/_next/static/media/tiktok.00837589.svg"
                          alt=""
                        />{' '}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="footerBottomLinks">
              <ul>
                <li>
                  <a href="/terms">Terms of Service</a> <span>|</span>
                </li>
                <li>
                  <a href="/privacy">Privacy Policy</a> <span>|</span>
                </li>
                <li>
                  <a href="">Cookies Policy</a>
                </li>
              </ul>
              <p>© 2022 Event Massi LLC. All Rights Reserved.</p>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
