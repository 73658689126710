import React from 'react';
import HeaderDocumentDetail from '../../components/HeaderDocumentDetail';
import CommonService from '../../services/CommonService';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContent } from '../../components/Toast';
import './Contact.css';

class Dashboard extends React.Component {
  state = {};

  componentDidMount() {}

  submitContactForm = async (e) => {
    e.preventDefault();
    toast(() => ToastContent('Sending.......'), {
      toastId: 'infoToast',
      hideProgressBar: false,
      type: toast.TYPE.INFO,
      autoClose: 1000,
    });
    try {
      var firstName = e.target.elements.firstname.value;
      var lastName = e.target.elements.lastname.value;
      var phone = e.target.elements.phone.value;
      var email = e.target.elements.email.value;
      var category = e.target.elements.category.value;
      var comments = e.target.elements.comments.value;
      var params = { firstName, lastName, phone, email, category, comments };
      let contactus = await CommonService.contactUsForm(params);
      toast.dismiss();
      if (contactus.success) {
        toast(() => ToastContent('Your message has been send successfully'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1500,
        });
      } else {
        toast(() => ToastContent('Error on sending message'), {
          toastId: 'errorToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1500,
        });
      }
    } catch (err) {
      toast.dismiss();
      toast(() => ToastContent('Something went wrong'), {
        toastId: 'errorToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
    }
  };

  //render method
  render() {
    return (
      <>
        <HeaderDocumentDetail title="Event Massi | Contact Us" />

        <div className="container margin-top-70 margin-bottom-50">
          <img
            className="d-flex mx-auto"
            src="https://www.beta.eventmassi.com/_next/static/media/contact-top-img.c1fddd5d.png"
            alt=""
          />
          <h1 className="Massi">Contact Us</h1>
          <div className="container ">
            <p className="text-center">
              Any Question Or Remarks? Just Write Us A Message!
            </p>
          </div>
          <div className="row mt-5">
            <div className="col-md-4">
              <div className="sidebar-textbox mx-lg-4 text-light   ">
                <h4 className="headline text-light  margin-bottom-20">
                  Contact Information
                </h4>
                <p>
                  Fill up the form and our Team will get back to you within 24
                  hours.
                </p>

                <ul className="contact-details  ">
                  <li>
                    <i className="im text-light im-icon-Map-Marker2" />
                    <h3 className="text-light mt-3">Long Island, NY </h3>
                    {/* <strong className="text-light">Location:</strong>{' '} */}
                  </li>

                  {/* <li>
                    <i className="im text-light im-icon-Globe"></i>{' '}
                    <strong className="text-light">Web:</strong>{' '}
                    <span>
                      <a
                        href="https://eventmassi.com/"
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        https://eventmassi.com/
                      </a>
                    </span>
                  </li> */}
                  <li>
                    <i className="im text-light im-icon-Envelope"></i>{' '}
                    {/* <strong className="text-light">E-Mail:</strong>{' '} */}
                    <h3 className="text-light mt-3">support@eventmassi.com</h3>
                    {/* <span>
                      <a href={`mailto:support@eventmassi.com`}>
                        support@eventmassi.com
                      </a>
                    </span> */}
                  </li>
                  <li>
                    <i className="im text-light im-icon-Telephone"></i>{' '}
                    {/* <strong className="text-light">Phone:</strong>{' '} */}
                    {/* <span className="text-light"> */}
                    {/* <a href={`tel:516216-9595`}>(516) 216-9595</a> */}
                    <h3 className="text-light mt-3">(516) 216-9595</h3>
                    {/* </span> */}
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-8 details  ">
              <section id="contact">
                {/* <h4 className="headline margin-bottom-35">Contact Form</h4> */}

                {/* <div id="contact-message"></div> */}

                <form onSubmit={this.submitContactForm}>
                  <div className="row mt-5">
                    <div className="col-md-6 ">
                      <div>
                        <label>
                          First Name<sup>*</sup>
                        </label>
                        <input
                          name="firstname"
                          type="text"
                          // id="firstname"
                          className="contact-form"
                          // placeholder="First Name"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6  ">
                      <div>
                        <label>
                          Last Name<sup>*</sup>
                        </label>
                        <input
                          name="lastname"
                          type="text"
                          // id="lastname"
                          className="contact-form"
                          // placeholder="Last Name"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <div>
                        <label>
                          Email <sup>*</sup>
                        </label>
                        <input
                          name="email"
                          type="email"
                          // id="email"
                          className="contact-form"
                          // placeholder="Email Address"
                          pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
                          required="required"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div>
                        <label>
                          Phone<sup>*</sup>
                        </label>
                        <input
                          name="phone"
                          type="number"
                          className="contact-form"
                          id="phone"
                          // placeholder="Contact number"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label className="fw-bold">Category</label>
                      <div className="radioButtonGrp">
                        <label
                          className="customRadioBtnContainer"
                          id="category"
                        >
                          <input type="radio" name="category" />
                          <span>General Inquiry</span>
                          <span className="checkmark"></span>
                        </label>
                        <label className="customRadioBtnContainer">
                          <input type="radio" name="category" />
                          <span>Technical Issue</span>
                          <span className="checkmark"></span>
                        </label>
                        <label className="customRadioBtnContainer">
                          <input type="radio" name="category" />
                          <span>Suggestion</span>
                          <span className="checkmark"></span>
                        </label>
                        <label className="customRadioBtnContainer">
                          <input type="radio" name="category" />
                          <span>Other</span>
                          <span className="checkmark"></span>
                        </label>
                      </div>
                      {/* <select
                        className="chosen-select-no-single contact-form"
                        id="category"
                        required
                      >
                        <option value="">Select Category</option>
                        <option value={'General Inquiry'}>
                          General Inquiry
                        </option>
                        <option value={'Technical Issue'}>
                          Technical Issue
                        </option>
                        <option value={'Suggestion'}>Suggestion</option>
                        <option value={'Other'}>Other</option>
                      </select> */}
                    </div>
                  </div>
                  <div>
                    <label className="mt-5">
                      Message<sup>*</sup>
                    </label>
                    <textarea
                      name="comments"
                      type="text"
                      cols="40"
                      rows="3"
                      id="comments"
                      className="contact-form "
                      placeholder="Type your Message"
                      spellCheck="true"
                      required="required"
                    ></textarea>
                  </div>

                  <input
                    type="submit"
                    className="submit button "
                    id="submit"
                    value="Submit Now!"
                  />
                </form>
              </section>
            </div>
          </div>
          <div className="contactBottomDetails">
            <h1>Need Help?</h1>
            <p>
              Are you an event vendor looking to advertise on Event Massi?
              Please click contact us or <a href="#_">create an account</a> to
              get started.
            </p>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
