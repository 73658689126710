import React from 'react';
// import Slider from 'react-slick';
import ReactTypingEffect from 'react-typing-effect';
import { Link } from 'react-router-dom';
import './home.css';
// import Search from '../pages/layouts/Search';
// import SearchBar from '../pages/layouts/Search';
class HomeBannerSlider extends React.Component {
  state = {};
  render() {
    return (
      // <div className="contentContainer">
      //   <div className="mobileSearchForm">
      //     <form className="headerSearchForm">
      //       <img
      //         src="https://www.beta.eventmassi.com/_next/static/media/search-icon.8302d07b.svg"
      //         alt="img"
      //       />
      //       <input
      //         type="search"
      //         className="form-control mr-sm-2"
      //         placeholder="Search"
      //         aria-label="Search"
      //       />
      //     </form>
      //   </div>
      <div>
        {/* <SearchBar /> */}
        <div className="homeFirstContainerWithImg">
          <div className="container col-12 ">
            <h2>
              Need help finding Nearby&nbsp;
              {/* <!-- Typed words can be configured in script settings at the bottom of this HTML file --> */}
              <span className="typed-words">
                <ReactTypingEffect
                  text={['Event Vendors?', ' Restaurants?', ' Hotels?']}
                  speed={80}
                  eraseSpeed={80}
                />
              </span>
            </h2>
            {/* <h2>Need help finding event vendors? </h2> */}
            <h4>Browse, Explore, Contact</h4>

            <form className="headerSearchForm">
              <img
                src="https://www.beta.eventmassi.com/_next/static/media/search-icon.8302d07b.svg"
                alt="search"
              />
              <input
                type="search"
                className="form-control mr-sm-2"
                placeholder="Find Your : Cupcakes"
                aria-label="Search"
              />
            </form>

            <div className="homeFirstSectionBtnsWrap">
              <Link className="" to="/blogs">
                <button className="firstSectionBtns">Visit our Blog</button>
              </Link>
              <Link className="px-2" to="/browse-vendors">
                <button className="firstSectionBtns">Massi Explore</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="homeSubFirstContainer">
          <div className="container">
            <h2>Are you a vendor or venue who wants to get listed?</h2>

            <button className="subFirstSectionBtns ">
              Create My Free Profile
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default HomeBannerSlider;

// import React from 'react';
// import { Link } from 'react-router-dom';
// import './home.css';
// const HomeBannerSlider = () => {
//   return (
//     <div className="contentContainer">
//       <div class="mobileSearchForm">
//         <form class="headerSearchForm">
//           <img
//             src="https://www.beta.eventmassi.com/_next/static/media/search-icon.8302d07b.svg"
//             alt="img"
//           />
//           <input
//             type="search"
//             class="form-control mr-sm-2"
//             placeholder="Search"
//             aria-label="Search"
//           />
//         </form>
//       </div>
//       <div className="homeFirstContainerWithImg">
//         <div className="container col-12 ">
//           <h2>Need help finding event vendors? </h2>
//           <h4>Browse, Explore, Contact</h4>

//           <form class="headerSearchForm">
//             <img
//               src="https://www.beta.eventmassi.com/_next/static/media/search-icon.8302d07b.svg"
//               alt="search"
//             />
//             <input
//               type="search"
//               class="form-control mr-sm-2"
//               placeholder="Find Your: Cupcakes"
//               aria-label="Search"
//             />
//           </form>

//           <div className="homeFirstSectionBtnsWrap">
//             <Link className="" to="/blogs">
//               <button className="firstSectionBtns">Visit our Blog</button>
//             </Link>
//             <Link className="px-2" to="/browse-vendors">
//               <button className="firstSectionBtns">Massi Explore</button>
//             </Link>
//           </div>
//         </div>
//       </div>
//       <div className="homeSubFirstContainer">
//         <div className="container">
//           <h2>Are you a vendor or venue who wants to get listed?</h2>

//           <button className="subFirstSectionBtns ">
//             Create My Free Profile
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HomeBannerSlider;
