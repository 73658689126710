/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import 'react-responsive-modal/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavLink } from 'react-router-dom';

import { ToastContent } from '../components/Toast';
import AuthService from '../services/AuthService';

class SignupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signinTab: false,
      signupTab: true,
    };
  }

  submitLoginForm = async (event) => {
    toast.dismiss();
    event.preventDefault();
    var email = event.target.elements.email.value;
    var password = event.target.elements.password.value;
    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (validateEmail && password) {
      toast(() => ToastContent('Please wait....'), {
        toastId: 'infoToast',
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
      let loginData = await AuthService.login(email, password);
      toast.dismiss();
      if (loginData.success) {
        toast(() => ToastContent('Successfully login'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        await localStorage.setItem('userToken', btoa(loginData.token));
        await localStorage.setItem(
          'userData',
          btoa(JSON.stringify(loginData.user))
        );
        this.props.loginModalClose(1);
      } else {
        toast(() => ToastContent(loginData.error), {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    } else {
      toast(() => ToastContent('Please enter valid credentials'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1000,
      });
    }
    return;
  };

  submitRegisterForm = async (event) => {
    toast.dismiss();
    event.preventDefault();
    var email = event.target.elements.registeremail.value;
    var password = event.target.elements.registerpassword.value;
    // var repeatpassword = event.target.elements.repeatpassword.value;
    var validateEmail = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(email);

    if (!validateEmail) {
      toast(() => ToastContent('Please enter valid email address'), {
        toastId: 'dangerToast',
        hideProgressBar: false,
        type: toast.TYPE.ERROR,
        autoClose: 1500,
      });
      return;
    }

    // if (password !== repeatpassword) {
    //   toast(() => ToastContent('Password & repeat password not matched'), {
    //     toastId: 'dangerToast',
    //     hideProgressBar: false,
    //     type: toast.TYPE.ERROR,
    //     autoClose: 1500,
    //   });
    //   return;
    // }

    if (validateEmail && password) {
      toast(() => ToastContent('Please wait....'), {
        toastId: 'infoToast',
        hideProgressBar: false,
        type: toast.TYPE.INFO,
        autoClose: 1000,
      });
      let loginData = await AuthService.register(email, password);
      toast.dismiss();
      if (loginData.success) {
        toast(() => ToastContent('Successfully register'), {
          toastId: 'successToast',
          hideProgressBar: false,
          type: toast.TYPE.SUCCESS,
          autoClose: 1000,
        });
        await localStorage.setItem('userToken', btoa(loginData.token));
        await localStorage.setItem(
          'userData',
          btoa(JSON.stringify(loginData.user))
        );
        this.props.loginModalClose(1);
      } else {
        toast(() => ToastContent(loginData.message), {
          toastId: 'dangerToast',
          hideProgressBar: false,
          type: toast.TYPE.ERROR,
          autoClose: 1000,
        });
      }
    }
  };

  componentDidMount() {}
  render() {
    return (
      <>
        <ToastContainer />
        <Modal
          isOpen={this.props.signinupModal}
          id="sign-in-dialog"
          onClose={() => {
            this.props.loginModalClose();
            this.setState({ signinTab: false, signupTab: true });
          }}
        >
          {/* <div className="modal-header  mx-auto"> */}

          {/* </div> */}

          <ModalBody style={{ backgroundColor: '#fff' }}>
            {/* <button
              type="button"
              className="close  text-light"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                this.props.loginModalClose();
                this.setState({ signinTab: false, signupTab: true });
              }}
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
            <div className="tab-content" onSubmit={this.submitRegisterForm}>
              <h1 className="text-center vendorName">Create An Account </h1>
              <span className="already">
                Already have an account?
                <NavLink className="mx-3 fw-bolder text-dark text-decoration-underline ">
                  Sign in
                </NavLink>
              </span>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.props.loginModalClose();
                  this.setState({ signinTab: false, signupTab: true });
                }}
              >
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/close-icon.6ef7d21d.svg"
                  alt=""
                />
              </button>
              <form className="register">
                <p className="form-row form-row-wide">
                  Email Address:
                  <input
                    type="text"
                    className="input-text"
                    name="email"
                    id="registeremail"
                    placeholder="Enter Email "
                    defaultValue=""
                  />
                </p>
                <p className="form-row form-row-wide">
                  Password:
                  <input
                    className="input-text"
                    type="password"
                    name="registerpassword"
                    placeholder="Create password"
                    id="registerpassword"
                    defaultValue=""
                  />
                </p>
                {/* <p className="form-row form-row-wide">
                  Repeat Password:
                  <input
                    className="input-text"
                    type="password"
                    name="repeatpassword"
                    placeholder="Repeat password"
                    id="repeatpassword"
                    defaultValue=""
                  />
                </p> */}
                <p class="formShortInfo mt-4 mx-5">
                  This information is used to create your account and WILL NOT
                  BE DISPLAYED ON YOUR PROFILE
                </p>
                <div className="customFormGroup">
                  <button
                    type="submit"
                    className="signUpBtn mt-4 d-flex mx-auto"
                    name="register"
                    value="Register"
                  >
                    Sign up
                  </button>
                </div>
                <p class="formShortInfo  mx-5">
                  By signing up, you agree with the Terms of Service and Privacy
                  Policy
                </p>
              </form>
            </div>

            <div class="signUpWithBox">
              <span>Or Sign Up With</span>
            </div>
            <div class="socialLoginBoxes mt-3">
              <a class="appleLogin">
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/apple-icon.18d9951b.svg"
                  alt=""
                />
                <span>Apple ID</span>
              </a>
              <button>
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/g-plus-icon.c93f23d6.svg"
                  alt=""
                />
                <span>Google</span>
              </button>
              <button class="facebookLogin">
                <img
                  src="https://www.beta.eventmassi.com/_next/static/media/fb-fav-icon.0e844166.svg"
                  alt=""
                />
                <span>Facebook</span>
              </button>
            </div>
            <p class="formShortInfo mx-5 mt-5">
              We dont post anything to your social media, they are just used to
              create your account.
            </p>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default SignupModal;
