import React from "react";
import withRouter from '../../helper/withRouter';
import HeaderDocumentDetail from "../../components/HeaderDocumentDetail";
import VendorService from "../../services/VendorService";
import VendorDetail from "../../components/VendorDetail";
class VendorProfilePage extends React.Component {
  state = {
    vendorProfile: null,
    vendorId: null
  };

  async componentDidMount() {
    let vendorId = this.props.params.vendorId;
    vendorId = atob(vendorId);
    this.setState({vendorId: vendorId});
    this.getVendorProfileById(vendorId);
  }

  getVendorProfileById = async(vendorid) =>{
    let vendorProfile = await VendorService.getVendorProfileById(vendorid);
    this.setState({vendorProfile: vendorProfile});
    window.scrollTo(0, 0);
  }
  
  //render method
  render() {
    return (
      <>
        <HeaderDocumentDetail title="Event Massi | Vendor Profile" />
        <VendorDetail vendorProfile={this.state.vendorProfile} loginFormShow={(value) => this.props.loginFormShow(value)}/>
      </>
    );
  }
}

export default withRouter(VendorProfilePage);
