import React from "react";
class OnPageErrorMessages extends React.Component {
  state = {};

  componentDidMount() {}

  render() {
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <section id="not-found" className="center">
                <h2>
                  ERROR <i className="fa fa-question-circle"></i>
                </h2>
                <p>
                  {this.props.networkErrorMessage}
                </p>

                
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default OnPageErrorMessages;
